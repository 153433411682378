import { RepoProvider, RepoService } from "../RepoService";

export class FakeRepoProvider extends RepoProvider {
  async getRepositories() {
    switch (this.name) {
      case "meta.sr.ht":
        return [{ name: "VanishingTrumpet", url: "blabla" }];
      case "gitlab.com":
        return [
          { name: "My first project on gitlab", url: "url1" },
          { name: "My second project on gitlab", url: "url2" },
        ];
      case "mylittleforge.com":
      default:
        return [];
    }
  }
}

function delay<T>(fn: () => T, time: number): Promise<T> {
  return new Promise((resolve) => {
    window.setTimeout(() => {
      resolve(fn());
    }, time);
  });
}

export class FakeRepoService implements RepoService {
  private callCount = 0;
  async getRepoProviders() {
    return delay(() => {
      this.callCount++;
      const json = {
        "meta.sr.ht": {
          profileUrl:
            "https://myjupyterhubinstance.com/hub/sources/sourcehut/meta.sr.ht/profile",
          type: "sourcehut",
          connectUrl:
            "https://myjupyterhubinstance.com/hub/sources/sourcehut/meta.sr.ht/connect",
        },
        "gitlab.com": {
          connectUrl:
            "https://myjupyterhubinstance.com/hub/sources/gitlab/gitlab.com/connect",
          type: "gitlab",
          profileUrl:
            "https://myjupyterhubinstance.com/hub/sources/gitlab/gitlab.com/profile",
        },
        "mylittleforge.com": {
          type: "gitlab",
          profileUrl:
            "https://myjupyterhubinstance.com/hub/sources/gitlab/mylittleforge.com/profile",
          connectUrl:
            "https://myjupyterhubinstance.com/hub/sources/gitlab/mylittleforge.com/connect",
        },
      };
      return new Map(
        Object.entries(json).map(([name, attributes]) => {
          const { type: type_, connectUrl, profileUrl } = attributes;
          return [
            name,
            new FakeRepoProvider(
              type_,
              name,
              this.callCount > 2,
              connectUrl,
              profileUrl,
            ),
          ];
        }),
      );
    }, 500);
  }

  async getRepoBranches() {
    return [];
  }
  async getRepoCommit() {
    return { sha: "mlk", message: "message" };
  }
}
