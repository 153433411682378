import React from "react";
import Form from "react-bootstrap/Form";

import { useDispatch, useSelector } from "react-redux";
import { State } from "../reducer";

import { ProjectServiceContext } from "../Services/ProjectService";

interface InstanceTypesFormProps {
  instanceType: string;
  onInstanceTypeChange: (instanceType: string) => void;
}

export const InstanceTypesForm = ({
  instanceType,
  onInstanceTypeChange,
}: InstanceTypesFormProps) => {
  const dispatch = useDispatch();
  const projectService = React.useContext(ProjectServiceContext);
  const instanceTypes = useSelector((state: State) => state.instanceTypes);

  React.useEffect(() => {
    projectService.getInstanceTypes().then((loadedInstanceTypes) => {
      dispatch({
        type: "SET_INSTANCE_TYPES",
        instanceTypes: loadedInstanceTypes,
      });
      if (!instanceType) {
        onInstanceTypeChange(Object.entries(loadedInstanceTypes)[0][0]);
      }
    });
  }, []);
  if (!instanceTypes || !instanceType) {
    return null;
  }
  return (
    <Form.Group className="mb-3">
      <Form.Label>Instance type</Form.Label>
      <Form.Select
        name="instanceType"
        value={instanceType}
        onChange={(e) => onInstanceTypeChange(e.currentTarget.value)}
      >
        {Array.from(Object.entries(instanceTypes), ([insType, insDesc]) => (
          <option key={insType} value={insType}>
            {insType} ({insDesc})
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );
};
