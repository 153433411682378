import React from "react";
import { NavLink } from "react-router-dom";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import styled from "styled-components";

import { Icon } from "./Utils/Icons";
import { useLoggedUser } from "./Utils/useLoggedUser";

export const MainMenu: React.FC = () => {
  const { user } = useLoggedUser();

  return (
    <>
      <MainMenuButtonGroup vertical>
        <MenuNavlink to="/projects" className={"btn btn-info"}>
          <span className="icon-right">
            <Icon iconName="list-ul" />
          </span>
          Projects
        </MenuNavlink>
        <MenuNavlink to="/profile" className={"btn btn-info"}>
          <span className="icon-right">
            <Icon iconName="user" />
          </span>
          Profile
        </MenuNavlink>
        {user.isIntegrator && (
          <>
            <MenuNavlink to="/users" className={"btn btn-info"}>
              <span className="icon-right">
                <Icon iconName="users" />
              </span>
              Users
            </MenuNavlink>
            <MenuNavlink to="/kube" className={"btn btn-info"}>
              <span className="icon-right">
                <Icon iconName="cube" />
              </span>
              Kubernetes
            </MenuNavlink>
          </>
        )}
      </MainMenuButtonGroup>
    </>
  );
};

const MainMenuButtonGroup = styled(ButtonGroup)`
  /* On screens that are less than 130px wide, make the sidebar into a topbar */
  @media (max-width: 1300px) {
    width: 100%;
    height: auto;
    position: relative;
  }

  width: 80%;
  padding-left: 2em;
`;

const MenuNavlink = styled(NavLink)`
  min-width: 170px;
`;
