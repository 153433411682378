import { DateTime, Duration } from "luxon";
import { UserService } from "../UserService";

function createUser(login: string) {
  return {
    login,
    isIntegrator: false,
    preferences: {
      sortCriteria: "name",
      sortAscendant: false,
      editableView: false,
    },
    creationDate: DateTime.local(),
    timeSpent: Duration.fromObject({ hours: 2, minutes: 7 }),
  };
}

const users = [
  {
    login: "me",
    isIntegrator: true,
    preferences: {
      sortCriteria: "name",
      sortAscendant: false,
      editableView: false,
    },
    creationDate: DateTime.local(),
    timeSpent: Duration.fromObject({ hours: 1 }),
  },
  createUser("jdoe"),
  createUser("user1"),
  createUser("user2"),
  createUser("user3"),
  createUser("user4"),
  createUser("jdoe2"),
  createUser("user13"),
  createUser("user23"),
  createUser("user33"),
  createUser("user43"),
  createUser("user15"),
  createUser("user25"),
  createUser("user35"),
  createUser("user45"),
];
export const fakeUserService: UserService = {
  getCurrentUser: async () => ({
    login: "me",
    isIntegrator: true,
    homePageUrl: "/projects",
    preferences: {
      sortCriteria: "name",
      sortAscendant: false,
      editableView: false,
    },
  }),
  getUsers: async () => users,
  setCurrentProject: () => 1,
  grantIntegratorRights: () => Promise.resolve(),
  revokeIntegratorRights: () => Promise.resolve(),
  deleteUser: () => Promise.resolve(),
  updatePreferences: () => Promise.resolve(),
  addUsers: async () => Promise.resolve(""),
};
