import React from "react";
import styled from "styled-components";
import Form from "react-bootstrap/Form";

import { AppIcon } from "../Model/App";
import { ThumbnailTextWrapper } from "./AppsCards";
import { CardThumbnail } from "./AppsCards";

export const EditIconForm: React.FC<{
  appName: string;
  appIcon: AppIcon;
  handleIconChange: (icon: AppIcon) => void;
}> = ({ appName, appIcon, handleIconChange }) => {
  const [startColor, setStartColor] = React.useState(appIcon.startColor);
  const [stopColor, setStopColor] = React.useState(appIcon.stopColor);
  const [imgUrl, setImageUrl] = React.useState(appIcon.imgUrl);

  React.useEffect(() => {
    if (!stopColor) {
      setStopColor(startColor);
    }
    handleIconChange({ startColor, stopColor, imgUrl });
  }, [stopColor, startColor, imgUrl]);

  return (
    <>
      <Form.Group className="mb-3">
        <Thumbnail start_color={startColor} stop_color={stopColor}>
          <ThumbnailTextWrapper>
            {imgUrl ? (
              <img width="55px" height="55px" src={imgUrl} />
            ) : (
              <>
                {appName.split(" ").map((word) => word.charAt(0).toUpperCase())}
              </>
            )}
          </ThumbnailTextWrapper>
        </Thumbnail>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Start Color</Form.Label>
        <Form.Control
          name="startColor"
          value={startColor}
          onChange={(e) => setStartColor(e.currentTarget.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Stop Color</Form.Label>
        <Form.Control
          name="stopColor"
          value={stopColor}
          onChange={(e) => setStopColor(e.currentTarget.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Image Url</Form.Label>
        <Form.Control
          name="imgUrl"
          value={imgUrl}
          onChange={(e) => setImageUrl(e.currentTarget.value)}
        />
      </Form.Group>
    </>
  );
};

const Thumbnail = styled(CardThumbnail)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
`;
