import React from "react";
import styled from "styled-components";

interface WizardStepsProps {
  steps: string[];
  activeStep: number;
}

export function WizardSteps({ steps, activeStep }: WizardStepsProps) {
  return (
    <WizardStepsWrapper>
      {steps.map((step, stepIdx) => (
        <StepBox key={stepIdx} active={stepIdx === activeStep}>
          <StepNumber>{stepIdx + 1}</StepNumber>
          <StepTitle>{steps[stepIdx]}</StepTitle>
        </StepBox>
      ))}
    </WizardStepsWrapper>
  );
}

const WizardStepsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 80px;
  border-bottom: 1px solid #dfe1ea;
`;

interface StepBoxProps {
  active: boolean;
}

const StepBox = styled.div<StepBoxProps>`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;

  font-weight: bold;
  color: ${(props) => (props.active ? "#3a5a8e" : "#d5d5d5")};

  background: ${(props) => (props.active ? "#f2f4f7" : "white")};

  &:not(:last-child) {
    border-right: 1px solid #dfe1e9;
  }
`;

const StepNumber = styled.div`
  font-size: 40px;
  min-width: 40px;
  margin-right: 10px;
  text-align: center;
`;

const StepTitle = styled.div`
  font-size: 0.9em;
  padding-right: 10px;
`;
