import React from "react";
import Button from "react-bootstrap/Button";
import styled from "styled-components";

import { BuildStatus, ServerStatus } from "../Model/App";
import { serviceUrlForServer } from "./urls";
import { WebServiceId } from "../Model/WebService";

interface ServerControlProps<I> {
  onStart: (serviceId: WebServiceId) => void;
  buildStatus: BuildStatus;
  runnable: {
    id: I;
    runConfig: {
      serverStatus: ServerStatus;
      serverName: string;
      mainWebService: WebServiceId;
    };
  };
}

export function ServerControl<I>({
  onStart,
  buildStatus,
  runnable: { id, runConfig },
}: ServerControlProps<I>) {
  let serverState: string;
  switch (runConfig.serverStatus) {
    case "READY":
      serverState = "Started";
      break;
    case "SPAWNING":
      serverState = "Spawning";
      break;
    case "TERMINATED":
      serverState = "Stopping";
      break;
    default:
      serverState = "Stopped";
  }

  let buttonColor: string;
  switch (buildStatus) {
    case "BUILDING":
      buttonColor = "lightblue";
      break;
    case "ABORT":
      buttonColor = "#777";
      break;
    case "FAILURE":
      buttonColor = "#d9534f";
      break;
    default:
      buttonColor = "grey";
  }

  return (
    <>
      {buildStatus !== "SUCCESS" ? (
        <MainButton
          title={buildStatus}
          style={{ backgroundColor: `${buttonColor}` }}
          size="sm"
          disabled
        >
          {buildStatus}
        </MainButton>
      ) : (
        <div>
          {serverState === "Started" && (
            <MainButton
              title={`Go to ${runConfig.mainWebService}`}
              id={`build-actions-${id}`}
              size="sm"
              variant="primary"
              href={serviceUrlForServer(
                runConfig.serverName,
                runConfig.mainWebService,
              )}
            >
              ACCESS
            </MainButton>
          )}

          {serverState === "Spawning" && (
            <MainButton
              title="Start"
              id={`build-actions-${id}`}
              size="sm"
              variant="warning"
              href={serviceUrlForServer(
                runConfig.serverName,
                runConfig.mainWebService,
              )}
            >
              <i
                className="fa fa-spinner fa-pulse fa-fw"
                aria-hidden="true"
              ></i>
              SPAWNING
            </MainButton>
          )}
          {serverState === "Stopping" && (
            <MainButton
              title="Start"
              id={`build-actions-${id}`}
              size="sm"
              variant="warning"
              disabled
            >
              <i
                className="fa fa-spinner fa-pulse fa-fw"
                aria-hidden="true"
              ></i>
              STOPPING
            </MainButton>
          )}
          {serverState === "Stopped" && (
            <MainButtonStart
              title={`Start ${runConfig.mainWebService}`}
              id={`build-actions-${id}`}
              size="sm"
              variant="success"
              onClick={() => onStart(runConfig.mainWebService)}
            >
              START
            </MainButtonStart>
          )}
        </div>
      )}
    </>
  );
}

const MainButton = styled(Button)`
  border-radius: 24px;
  width: 10em;
  height: 2.8em;
  align-content: space-evenly;
`;

const MainButtonStart = styled(Button)`
  border-radius: 24px;
  width: 10em;
  height: 2.8em;
  background-color: #2dbb67;
  border-color: #25a258;
  align-content: space-evenly;
  &:hover {
    background-color: #25a258;
    border-color: #25a258;
  }
  &:focus {
    background-color: #25a258;
    border-color: #25a258;
  }
  &:active {
    background-color: #25a258;
    border-color: #25a258;
  }
`;
