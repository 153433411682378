import React from "react";
import styled from "styled-components";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { useModal } from "../Utils/Modal";
import { ProjectServiceContext } from "../Services/ProjectService";
import { ProjectMembersProps } from "./ProjectMembers";

export const ProjectConfiguration: React.FC<ProjectMembersProps> = ({
  project,
  updateProject,
}) => {
  const projectService = React.useContext(ProjectServiceContext);
  const [projectName, setProjectName] = React.useState<string>("");
  const [projectDesc, setProjectDesc] = React.useState<string>("");
  const [deleteMembers, setDeleteMenbers] = React.useState(false);
  const toggleDeleteMemebers = () => setDeleteMenbers(!deleteMembers);

  React.useEffect(() => {
    setProjectName(project.name);
    setProjectDesc(project.description);
  }, []);

  const {
    show: showDeleteModal,
    open: openDeleteModal,
    close: closeDeleteModal,
  } = useModal();

  function save() {
    const data = {
      name: projectName,
      description: projectDesc,
    };
    projectService.editProject(project.id, data).then((response) => {
      if (response.ok) {
        updateProject();
      }
    });
  }

  function handleDeleteClicked(event: React.FormEvent) {
    event.preventDefault();
    projectService.deleteProjectMembers(project.id).then((resp) => {
      if (resp.ok) {
        projectService.deleteProject(project.id).then((response) => {
          if (response.ok) {
            closeDeleteModal();
            window.location.href = "/app/projects";
          }
        });
      }
    });
  }

  function handleProjectNameChange(event: React.FormEvent<HTMLInputElement>) {
    setProjectName(event.currentTarget.value);
  }

  function handleProjectDescChange(event: React.FormEvent<HTMLInputElement>) {
    setProjectDesc(event.currentTarget.value);
  }

  if (!project) {
    return <> </>;
  }

  return (
    <section>
      <ConfigSectionTitle>General</ConfigSectionTitle>
      <p>
        name
        <input
          className="form-control"
          name="projectName"
          value={projectName}
          onChange={handleProjectNameChange}
        />
      </p>
      <p>
        description
        <input
          className="form-control"
          name="projectDesc"
          value={projectDesc}
          onChange={handleProjectDescChange}
        />
      </p>
      <ConfirmButton
        title={`Save project ${project.name}`}
        size="sm"
        onClick={save}
      >
        Confirm
      </ConfirmButton>
      <br />
      <ConfigSectionTitle>Delete Project</ConfigSectionTitle>
      <ConfirmButton
        title={`Delete project ${project.name}`}
        size="sm"
        onClick={openDeleteModal}
      >
        Delete
      </ConfirmButton>
      <WarningMessage>
        This will permanently delete the project and its apps.
      </WarningMessage>
      <Modal show={showDeleteModal} onHide={closeDeleteModal}>
        <Modal.Header closeButton={true}>
          <Modal.Title>Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h2>
            <i className="fa fa-warning"></i>
          </h2>
          <h4>
            This will permanently delete <b>{project.name}</b> project and its
            apps.
          </h4>
          <input
            type="checkbox"
            className=""
            name="deleteMembers"
            checked={deleteMembers}
            onChange={toggleDeleteMemebers}
          />{" "}
          <label htmlFor="deleteMembers">
            Delete members - This will delete users who are only in this project
          </label>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-danger" onClick={closeDeleteModal}>
            Cancel
          </button>
          <button className="btn btn-primary" onClick={handleDeleteClicked}>
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

const ConfirmButton = styled(Button)`
  margin: 0.5em;
`;

const WarningMessage = styled.span`
  margin-left: 1em;
  color: red;
  font-weight: bold;
`;

export const ConfigSectionTitle = styled.h3`
  margin-bottom: 1em;
`;
