import React from "react";
import { DateTime } from "luxon";

export type Server = {
  owner: string;
  name: string;
  started: DateTime;
  lastActivity: DateTime;
  ready: boolean;
};

export interface JupyterhubService {
  getServers(): Promise<Server[]>;
  stopServer(login: string, serverName: string): Promise<Response>;
}

export const JupyterhubServiceContext = React.createContext<JupyterhubService>({
  async getServers() {
    const response = await fetch(`/services/repo2img/api/servers/`);
    const body = await response.json();
    if (response.ok) {
      return body.data.map((server: any) => {
        return {
          ...server,
          started: DateTime.fromISO(server.started),
          lastActivity: DateTime.fromISO(server.lastActivity),
        };
      });
    } else {
      console.error(body.reason);
    }
  },

  async stopServer(login: string, serverName: string) {
    return fetch(`/services/repo2img/api/${login}/servers/${serverName}`, {
      method: "DELETE",
    });
  },
});
