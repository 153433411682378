import React, { Suspense, lazy } from "react";
const LazyFileBrowser = lazy(() => import("../Components/FileBrowser"));

interface FileBrowserProps {
  projectId?: string;
}

export const AsyncFileBrowser: React.FC<FileBrowserProps> = (
  props: FileBrowserProps,
) => {
  return (
    <div>
      <Suspense fallback={<div>Loading...</div>}>
        <LazyFileBrowser {...props} />
      </Suspense>
    </div>
  );
};
