import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Table from "react-bootstrap/Table";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import FormGroup from "react-bootstrap/FormGroup";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import FormControl from "react-bootstrap/FormControl";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { DateTime, Duration } from "luxon";
import styled from "styled-components";

import { Page } from "../Components/Page";
import { MainMenu } from "../MainMenu";
import { Map } from "immutable";
import { SpinnerIcon } from "../Utils/Icons";
import { UserServiceContext } from "../Services/UserService";
import { useModal } from "../Utils/Modal";
import { useModalWithData } from "../Utils/Modal";
import { Thumbnail } from "../Utils/Thumbnail";
import { MainTabStyle } from "../Utils/MainTab";
import { Icon } from "../Utils/Icons";
import { RoleBadge } from "../Components/RoleBadge";
import { useLoggedUser } from "../Utils/useLoggedUser";
import {
  Project,
  ProjectDescriptor,
  ProjectId,
  castProjectId,
} from "../Model/Project";
import { ProjectServiceContext } from "../Services/ProjectService";
import { iframeStyle } from "./IframePage";

interface User {
  login: string;
  isIntegrator: boolean;
  creationDate: DateTime;
  timeSpent: Duration;
}

export const UsersPage: React.FC = ({}) => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = React.useState(
    document.location.hash.substring(1),
  );

  const handleTabSelect = (tabName: string) => {
    setSelectedTab(tabName);
    navigate(`#${tabName}`);
    if (tabName === "authArea") {
      const iframe = document.getElementById("authorize_iframe");
      (iframe as any).contentWindow.location.reload(true);
    }
  };

  const tabUsers = (
    <>
      <Icon iconName="users" />
      <span className="margin-left">Users</span>
    </>
  );
  const tabAuthArea = (
    <>
      <Icon iconName="user-plus" />
      <span className="margin-left">Authorization Area</span>
    </>
  );

  return (
    <Page.Content>
      <div className="container">
        <Page.Header className="row">
          <h1 className="col-md-8 col-mdOffset-2 col-12">
            <Thumbnail $size={60}>
              <Icon iconName="users" />
            </Thumbnail>
            <span className="margin-left">Users</span>
          </h1>
        </Page.Header>
        <div className="row">
          <div className="col-md-2 context-menu">
            <MainMenu />
          </div>
          <div className="col-md-8 managment-content">
            <div className="managment-header" />
            <Tabs
              activeKey={selectedTab ? selectedTab : "users"}
              onSelect={(e) => handleTabSelect(e as any)}
              transition={false}
              id="project-tab"
            >
              <FixMainTab
                style={MainTabStyle}
                eventKey="users"
                title={tabUsers}
              >
                <UsersPageContent />
              </FixMainTab>
              <Tab style={MainTabStyle} eventKey="authArea" title={tabAuthArea}>
                <iframe
                  id="authorize_iframe"
                  src="/hub/authorize"
                  style={iframeStyle}
                />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </Page.Content>
  );
};

const UsersPageContent = () => {
  const { user: loggedUser } = useLoggedUser();
  const userService = React.useContext(UserServiceContext);
  const [shouldLoad, setShouldLoad] = React.useState(true);
  const [users, setUsers] = React.useState<User[] | null>(null);
  const projectService = React.useContext(ProjectServiceContext);
  const [project, setProject] = React.useState<Project | null>(null);
  const [projects, setProjects] =
    React.useState<Map<ProjectId, ProjectDescriptor>>(Map());
  const [userFilter, setUserFilter] = React.useState("");
  const {
    data: selectedUsersLogins,
    show: showDeleteModal,
    open: openDeleteModal,
    close: closeDeleteModal,
  } = useModalWithData<string[]>();
  const [newUsersLogins, setNewUsersLogins] = React.useState("");
  const [newUsersPassword, setNewUsersPassword] = React.useState("");
  const [newUsersProjects, setNewUsersProjects] = React.useState<string[]>([]);
  const [message, setMessage] = React.useState<null | string>(null);
  const [selectedUsers, setSelectedUsers] = React.useState<string[]>([]);
  const {
    show: showAddUsersModal,
    open: openAddUsersModal,
    close: closeAddUsersModal,
  } = useModal();

  function updateProjets() {
    projectService.getProjects().then(setProjects);
  }

  React.useEffect(() => {
    updateProjets();
  }, []);

  React.useEffect(() => {
    if (shouldLoad === true) {
      setShouldLoad(false);
      userService.getUsers().then((_users) => {
        setShouldLoad(false);
        setUsers(_users);
      });
    }
  }, [shouldLoad, userService, setShouldLoad, setUsers]);

  function userByLogin(login: string) {
    if (users === null) {
      return null;
    }
    return users.find((u) => u.login === login) || null;
  }
  function handleToggleIntegratorRights(login: string) {
    const user = userByLogin(login);
    if (user === null) {
      return;
    }
    if (user.isIntegrator) {
      userService.revokeIntegratorRights(login).then(() => setShouldLoad(true));
    } else {
      userService.grantIntegratorRights(login).then(() => setShouldLoad(true));
    }
  }

  function toggleSelectedUser(login: string) {
    setSelectedUsers(() => {
      if (selectedUsers.includes(login)) {
        return selectedUsers.filter((user) => user != login);
      } else {
        return [...selectedUsers, login];
      }
    });
  }

  function selectAll() {
    const displayedUsers = usersToDisplay
      .filter((user) => !user.isIntegrator)
      .map((user) => user.login);
    if (displayedUsers.toString() !== selectedUsers.toString()) {
      setSelectedUsers(displayedUsers);
    } else {
      setSelectedUsers([]);
    }
  }

  function handleDeleteUsersClicked(logins: string[]) {
    closeDeleteModal();
    for (const login of logins) {
      userService.deleteUser(login).then(() => setShouldLoad(true));
    }
    setSelectedUsers([]);
  }

  if (users === null) {
    return (
      <>
        <SpinnerIcon />
        Loading users...
      </>
    );
  }

  const handleTabSelect = (event: any) => {
    const projectId = event.currentTarget.value;
    if (projectId === "") {
      setProject(null);
    } else {
      projectService
        .getProject(castProjectId(projectId))
        .then((currentProject) => {
          if (currentProject) {
            setProject(currentProject);
          }
        });
    }
  };
  const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    userService
      .addUsers(newUsersLogins, newUsersPassword, newUsersProjects)
      .then(setMessage);
    setMessage(null);
  };

  function isInProject(user: User) {
    return (
      project === null ||
      project.members.some((m) => m.login === user.login) ||
      project.owner === user.login
    );
  }

  const handleUserNameFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filterValue = e.currentTarget.value.toLowerCase();
    setUserFilter(filterValue);
  };

  const handleNewUsersProjects = (
    event: React.FormEvent<HTMLSelectElement>,
  ) => {
    setNewUsersProjects(
      Array.from(event.currentTarget.options)
        .filter((o) => o.selected)
        .map((o) => o.value),
    );
  };

  const handleCloseAddUsersModal = () => {
    closeAddUsersModal();
    setShouldLoad(true);
  };

  const usersToDisplay = users
    .filter(isInProject)
    .filter(
      (u) =>
        u.login.includes(userFilter) ||
        (u.creationDate.setLocale("en-gb").toISODate() || "").includes(
          userFilter,
        ),
    );

  return (
    <div>
      <FormGroup>
        <Row>
          <Col sm={4}>
            <FormControl
              placeholder="filter"
              value={userFilter}
              onChange={(e) => handleUserNameFilter(e as any)}
            />
          </Col>
          <Col sm={2}>
            <Form.Select
              onChange={(e) => handleTabSelect(e as any)}
              value={project ? project.id.toString() : ""}
            >
              <option value="">All Projects</option>
              {Array.from(projects.values(), (project) => (
                <option
                  key={project.id.toString()}
                  value={project.id.toString()}
                >
                  {project.name}
                </option>
              ))}
            </Form.Select>
          </Col>
          {loggedUser.isIntegrator && (
            <Col sm={6}>
              <RightButtonToolbar
                variant="default"
                onClick={() => openDeleteModal(selectedUsers)}
                disabled={selectedUsers.length === 0}
              >
                Delete Users <Icon iconName="trash" />
              </RightButtonToolbar>
              <RightButtonToolbar variant="default" onClick={openAddUsersModal}>
                Add Users
              </RightButtonToolbar>
            </Col>
          )}
        </Row>
      </FormGroup>
      <TableWrapper>
        <Table>
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Name</th>
              <th scope="col">Creation date</th>
              <th scope="col">Time spent</th>
              <th scope="col">Role</th>
              <th scope="col"></th>
              <th scope="col">
                <Button variant="default" onClick={selectAll}>
                  <b>Select All</b>
                </Button>
              </th>
            </tr>
          </thead>
          <tbody>
            {usersToDisplay.map((user) => (
              <tr key={user.login}>
                <td style={{ width: "5%" }}>
                  <Thumbnail $size={40}>
                    {user.login.charAt(0).toUpperCase()}
                  </Thumbnail>
                </td>
                <AlignTd title="User Name">{user.login}</AlignTd>
                <AlignTd title="User Creation Date">
                  {user.creationDate.setLocale("en-gb").toISODate()}
                </AlignTd>
                <AlignTd title="User Time Spent">
                  {user.timeSpent
                    .shiftTo("days", "hours", "minutes")
                    .toHuman({ unitDisplay: "short" })}
                </AlignTd>
                <AlignTd title="User Role">
                  <UserRoleBadge bg="light">
                    {user.isIntegrator ? "Integrator" : "Not Integrator"}
                  </UserRoleBadge>
                </AlignTd>
                <td title="Delete User">
                  <DropdownButton
                    title="Actions"
                    id={`actions-${user.login}`}
                    variant="default"
                  >
                    <Dropdown.Item
                      onClick={() => handleToggleIntegratorRights(user.login)}
                    >
                      {user.isIntegrator ? "Revoke" : "Grant"} Integrator Rights
                    </Dropdown.Item>
                    <Dropdown.Item href={`/../change-password/${user.login}`}>
                      Change password
                    </Dropdown.Item>
                  </DropdownButton>
                </td>
                <td>
                  {!user.isIntegrator && (
                    <input
                      type="checkbox"
                      checked={selectedUsers.includes(user.login)}
                      onChange={() => toggleSelectedUser(user.login)}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableWrapper>
      {selectedUsersLogins && (
        <Modal show={showDeleteModal} onHide={closeDeleteModal}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Deletion</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3>
              This will permanently remove selected users and their projects,
              and apps.
            </h3>
            <p>
              Selected users: <b>{selectedUsersLogins.toString()}</b>
            </p>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-danger" onClick={closeDeleteModal}>
              Cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={() => handleDeleteUsersClicked(selectedUsersLogins)}
            >
              Delete
            </button>
          </Modal.Footer>
        </Modal>
      )}
      <Modal show={showAddUsersModal} onHide={handleCloseAddUsersModal}>
        <Modal.Header closeButton={true}>
          <Modal.Title>Add Users</Modal.Title>
        </Modal.Header>
        <form onSubmit={onSubmitHandler}>
          <Modal.Body>
            <h3>This will add and authorize a bunch of users</h3>
            <p>
              logins (separate with ;)
              <textarea
                className="form-control"
                name="users"
                autoFocus
                required
                cols={30}
                rows={4}
                value={newUsersLogins}
                onChange={(e) => setNewUsersLogins(e.currentTarget.value)}
              />
            </p>
            <p>
              password
              <input
                className="form-control"
                name="password"
                required
                value={newUsersPassword}
                onChange={(e) => setNewUsersPassword(e.currentTarget.value)}
              />
            </p>
            <p>
              projects
              <select
                className="form-control custom-select"
                multiple
                required
                value={newUsersProjects || []}
                onChange={handleNewUsersProjects}
              >
                {Array.from(projects.values(), (project) => (
                  <option key={project.id.toString()} value={project.name}>
                    {project.name}
                  </option>
                ))}
              </select>
            </p>
            {message && <pre>{message}</pre>}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-danger"
              onClick={handleCloseAddUsersModal}
            >
              {message ? "Close" : "Cancel"}
            </button>
            <button className="btn btn-primary" type="submit">
              Add users
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

const AlignTd = styled.td`
  vertical-align: middle !important;
`;

const UserRoleBadge = styled(RoleBadge)`
  margin-left: 1em;
`;

const RightButtonToolbar = styled(Button)`
  float: right;
  margin-left: 1em;
`;

const FixMainTab = styled(Tab)`
  overflow-y: unset;
`;

const TableWrapper = styled.div`
  height: 600px;
  overflow-y: auto;
`;
