import React from "react";

import { Commit, Branch } from "../Model/Project";

export interface RepoDescriptor {
  name: string;
  url: string;
}

export class ConnectionError extends Error {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, ConnectionError.prototype);
  }
}

export class RepoProvider {
  constructor(
    public readonly type: string,
    public readonly name: string,
    public readonly authorized: boolean,
    public readonly connectUrl: string,
    private readonly profileUrl: string,
  ) {}

  async getRepositories(): Promise<RepoDescriptor[]> {
    return fetch(this.profileUrl, {
      headers: { Accept: "application/json" },
    })
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        } else {
          let message = "Unexpected Error";
          if (response.status === 401) {
            message = response.statusText;
          }
          throw new ConnectionError(`Error: ${message}`);
        }
      })
      .then((userProfile) => userProfile.projects);
  }
}

export interface RepoService {
  getRepoProviders(): Promise<Map<string, RepoProvider>>;
  getRepoBranches(repoUrl: string): Promise<Branch[]>;
  getRepoCommit(repoUrl: string, changeset: string): Promise<Commit>;
}

export const RepoServiceContext = React.createContext<RepoService>({
  async getRepoProviders() {
    const response = await fetch("/sources", {
      headers: { Accept: "application/json" },
    });
    const json: Record<string, any> = await response.json();
    return new Map(
      Object.entries(json).map(([name, attributes]) => {
        const { type: type_, authorized, connectUrl, profileUrl } = attributes;
        return [
          name,
          new RepoProvider(type_, name, authorized, connectUrl, profileUrl),
        ];
      }),
    );
  },

  async getRepoBranches(repoUrl: string) {
    const url = encodeURIComponent(repoUrl);
    const response = await fetch(`/services/repo2img/api/repo/${url}`);
    if (response.ok) {
      const data = await response.json();
      return data.branches;
    } else {
      console.error(response);
    }
  },

  async getRepoCommit(repoUrl: string, changeset: string) {
    const url = encodeURIComponent(repoUrl);
    const response = await fetch(
      `/services/repo2img/api/repo/${url}/changeset/${changeset}`,
    );
    if (response.ok) {
      const data = await response.json();
      return data.commit;
    } else {
      console.error(response);
    }
  },
});
