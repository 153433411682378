import React from "react";
import styled from "styled-components";

const FooterDiv = styled.footer`
  text-align: center;
  border-top: 1px solid #dedede;
  background-color: #f5f5f5;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1em;
  line-height: 0.6em;
  z-index: 5;
`;

export const Footer: React.FC = ({}) => {
  return (
    <FooterDiv>
      Made with ✨ by{" "}
      <a
        target="_blank"
        rel="nofollow noreferrer noopener"
        href="https://logilab.fr"
      >
        Logilab
      </a>
    </FooterDiv>
  );
};
