import React from "react";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Pagination from "react-bootstrap/Pagination";
import Table from "react-bootstrap/Table";
import { Map, OrderedSet } from "immutable";
import styled from "styled-components";

import { Project, ProjectMemberRole, ProjectMember } from "../Model/Project";
import { ProjectServiceContext } from "../Services/ProjectService";
import { UserServiceContext } from "../Services/UserService";
import { Thumbnail } from "../Utils/Thumbnail";
import { Icon } from "../Utils/Icons";
import { IconButton } from "../Utils/IconButton";
import { List, ListItemName } from "../Components/List";
import { OwnerRoleBadge, RoleBadge } from "../Components/RoleBadge";

export interface ProjectMembersProps {
  project: Project;
  updateProject: () => void;
}

export const ProjectMembers: React.FC<ProjectMembersProps> = ({
  project,
  updateProject,
}) => {
  const userService = React.useContext(UserServiceContext);
  const [allUsers, setUsers] = React.useState<string[]>([]);
  const projectService = React.useContext(ProjectServiceContext);
  const [memberName, setMemberName] = React.useState("");
  const [role, setRole] = React.useState<ProjectMemberRole>("GUEST");

  function updateUsers() {
    userService.getUsers().then((users) => setUsers(users.map((u) => u.login)));
  }

  React.useEffect(() => {
    updateUsers();
  }, [setUsers]);

  function handleMemberNameChanged(event: any) {
    setMemberName((event.target as HTMLInputElement).value);
  }

  function handleSubmit(event: any) {
    event.preventDefault();
    projectService
      .addProjectMember(project.id, memberName, role)
      .then((response) => {
        if (response.ok) {
          updateProject();
        }
      });
    setMemberName("");
  }

  function deleteMember(member: string) {
    projectService.deleteProjectMember(project.id, member).then((response) => {
      if (response.ok) {
        updateProject();
      }
    });
  }

  function toggleMemberRole(member: string) {
    projectService
      .toggleProjectMemberRole(project.id, member)
      .then((response) => {
        if (response.ok) {
          updateProject();
        }
      });
  }
  if (!project) {
    return <> </>;
  }
  const { owner, members } = project;
  const availableUsers = OrderedSet(allUsers).subtract([
    ...Array.from(members.keys()),
    owner,
  ]);

  return (
    <section>
      <div style={{ margin: "1em" }}>
        {members.isEmpty() ? (
          <>
            <MemberSectionTitle>Members</MemberSectionTitle>
            <p>This project has no members</p>
          </>
        ) : (
          <>
            <AdditionnalInformations>
              <List>
                <ListItemName>
                  Own by{" "}
                  <OwnerRoleBadge className="bg-light" title="owner">
                    {project.owner}
                  </OwnerRoleBadge>
                </ListItemName>
                <ListItemName>{members.size} member(s)</ListItemName>
              </List>
            </AdditionnalInformations>
            <ProjectMembersTable
              members={members}
              toggleMemberRole={toggleMemberRole}
              deleteMember={deleteMember}
            />
          </>
        )}
      </div>
      <div>
        <Form method="post" role="form" onSubmit={handleSubmit}>
          <h4>Add Member</h4>
          <MemberButtonGroup>
            <label htmlFor="memberLogin">Login </label>
            <MemberFormControl
              type="input"
              size="sm"
              list="available-users"
              name="memberLogin"
              value={memberName}
              onChange={handleMemberNameChanged}
            />
            <datalist id="available-users">
              {availableUsers.map((login) => (
                <option key={login} value={login}>
                  {login}
                </option>
              ))}
            </datalist>
          </MemberButtonGroup>
          <MemberButtonGroup>
            <label>Role </label>
            <MemberFormSelect
              value={role}
              size="sm"
              onChange={(event: any) =>
                setRole(
                  (event.target as HTMLInputElement).value as ProjectMemberRole,
                )
              }
            >
              <option value="GUEST">Guest </option>
              <option value="MAINTAINER">Maintainer</option>
            </MemberFormSelect>
          </MemberButtonGroup>
          <ConfirmButton
            type="submit"
            size="sm"
            disabled={!availableUsers.includes(memberName)}
          >
            Add to Project
          </ConfirmButton>
        </Form>
      </div>
    </section>
  );
};

const ProjectMembersTable: React.FC<{
  members: Map<string, ProjectMember>;
  toggleMemberRole: (member: string) => void;
  deleteMember: (member: string) => void;
}> = ({ members, toggleMemberRole, deleteMember }) => {
  const [activeMemberPage, setActiveMemberPage] = React.useState<number>(1);

  const nbPerPage = 10;
  const displayedMembers = members.slice(
    (activeMemberPage - 1) * nbPerPage,
    activeMemberPage * nbPerPage,
  );
  const nbMembersPages = Math.floor(members.count() / nbPerPage) + 1;
  const paginationButtons = [];
  for (let number = 1; number <= nbMembersPages; number++) {
    paginationButtons.push(
      <Pagination.Item
        key={`item-${number}`}
        onClick={() => setActiveMemberPage(number)}
        active={number === activeMemberPage}
      >
        {number}
      </Pagination.Item>,
    );
  }

  return (
    <>
      <MemberSection>
        <MemberTable responsive>
          <tbody>
            {Array.from(displayedMembers.values(), (member) => (
              <tr key={member.login}>
                <td>
                  <Thumbnail $size={25}>
                    {member.login.charAt(0).toUpperCase()}
                  </Thumbnail>
                </td>
                <td>
                  <ListItemName>{member.login}</ListItemName>
                </td>
                <td>
                  <IconButton
                    title="Toggle member role"
                    onClick={() => toggleMemberRole(member.login)}
                  >
                    <RoleBadge className="bg-light">{member.role}</RoleBadge>
                  </IconButton>
                </td>
                <td>
                  <IconButton
                    style={{ float: "right" }}
                    type="button"
                    onClick={() => deleteMember(member.login)}
                  >
                    <Icon iconName="trash" />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </MemberTable>
      </MemberSection>
      <MemberPagination size="sm">{paginationButtons}</MemberPagination>
    </>
  );
};

const MemberButtonGroup = styled(ButtonGroup)`
  margin-right: 1em;
`;

const MemberFormControl = styled(FormControl)`
  margin-left: 0.5em;
`;

const MemberFormSelect = styled(Form.Select)`
  margin-left: 0.5em;
`;

const MemberPagination = styled(Pagination)`
  margin: 0em;
`;

const MemberTable = styled(Table)`
  width: 60%;
`;

const MemberSection = styled.section`
  min-height: 440px;
`;

const AdditionnalInformations = styled.section`
  float: right;
`;

const ConfirmButton = styled(Button)`
  margin: 0.5em;
`;

export const MemberSectionTitle = styled.h3`
  margin-bottom: 1em;
`;
