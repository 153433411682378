import { Map } from "immutable";
import { Reducer } from "redux";

import {
  BuildStatus,
  FormType,
  Image,
  ImageId,
  ServerStatus,
  App,
  AppId,
} from "./Model/App";
import { Project, ProjectId } from "./Model/Project";
import { InstanceTypes } from "./Services/ProjectService";
import { assert } from "./Utils/utils";

export interface State {
  images: Map<ImageId, Image>;
  apps: Map<AppId, App>;
  projects: Map<ProjectId, Project>;
  currentProject: Project | "MISSING_OR_FORBIDDEN" | null;
  formType: FormType | null;
  showModal: boolean;
  instanceTypes: InstanceTypes | undefined;
}

type Action =
  | {
      type: "SET_IMAGES";
      images: Map<ImageId, Image>;
    }
  | {
      type: "CHANGE_APP_BUILD_STATUS";
      appId: AppId;
      status: BuildStatus;
    }
  | {
      type: "SET_APPS";
      apps: Map<AppId, App>;
    }
  | {
      type: "CHANGE_APP_SERVER_STATUS";
      appId: AppId;
      status: ServerStatus;
    }
  | {
      type: "SET_PROJECTS";
      projects: Map<ProjectId, Project>;
    }
  | {
      type: "SET_CURRENT_PROJECT";
      currentProject: Project | "MISSING_OR_FORBIDDEN" | null;
    }
  | {
      type: "CHANGE_PROJECT_APP_SERVER_STATUS";
      appId: AppId;
      status: ServerStatus;
    }
  | {
      type: "OPEN_MODAL";
      form: FormType;
    }
  | {
      type: "CLOSE_MODAL";
    }
  | {
      type: "SET_INSTANCE_TYPES";
      instanceTypes: InstanceTypes;
    };

export const reducer: Reducer<State, Action> = (
  state: State = {
    images: Map(),
    apps: Map(),
    projects: Map(),
    currentProject: null,
    formType: null,
    showModal: false,
    instanceTypes: undefined,
  },
  action: Action,
) => {
  let newApps = null;
  switch (action.type) {
    case "SET_IMAGES":
      return { ...state, images: action.images };
    case "CHANGE_APP_BUILD_STATUS":
      newApps = state.apps.update(action.appId, (app) => {
        assert(app);
        return { ...app, buildStatus: action.status };
      });
      return { ...state, apps: newApps };
    case "SET_APPS":
      return { ...state, apps: action.apps };
    case "CHANGE_APP_SERVER_STATUS":
      newApps = state.apps.update(action.appId, (app) => {
        assert(app);
        const runConfig = { ...app.runConfig, serverStatus: action.status };
        return { ...app, runConfig };
      });
      return { ...state, apps: newApps };
    case "SET_PROJECTS":
      return { ...state, projects: action.projects };
    case "SET_CURRENT_PROJECT":
      let project: Project | "MISSING_OR_FORBIDDEN" | null;
      if (action.currentProject === null) {
        project = "MISSING_OR_FORBIDDEN";
      } else {
        project = action.currentProject;
      }
      return { ...state, currentProject: project };
    case "CHANGE_PROJECT_APP_SERVER_STATUS":
      const currentProject = state.currentProject;
      if (currentProject && currentProject !== "MISSING_OR_FORBIDDEN") {
        newApps = currentProject.apps.update(action.appId, (app) => {
          assert(app);
          const runConfig = { ...app.runConfig, serverStatus: action.status };
          return { ...app, runConfig };
        });
        return {
          ...state,
          currentProject: { ...currentProject, apps: newApps },
        };
      } else {
        return state;
      }
    case "OPEN_MODAL":
      return {
        ...state,
        formType: action.form,
        showModal: true,
      };
    case "CLOSE_MODAL":
      return {
        ...state,
        formType: null,
        showModal: false,
      };
    case "SET_INSTANCE_TYPES":
      return {
        ...state,
        instanceTypes: action.instanceTypes,
      };
    default:
      return state;
  }
};
