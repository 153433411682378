import React from "react";

import { UserServiceContext } from "../Services/UserService";
import {
  LoggedUser,
  LoggedUserContext,
  UserPreferences,
} from "./LoggedUserContext";
import { SpinnerIcon } from "./Icons";

function getCurrentUserFromLocalStorage(): LoggedUser | null {
  const userJSON = localStorage.getItem("currentUser");
  if (userJSON === null) {
    return null;
  }
  return JSON.parse(userJSON);
}

export const LoggedUserProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const userService = React.useContext(UserServiceContext);
  const [user, setUser] = React.useState(() =>
    getCurrentUserFromLocalStorage(),
  );
  React.useEffect(() => {
    userService.getCurrentUser().then((user) => {
      setUser(user);
      localStorage.setItem("currentUser", JSON.stringify(user));
    });
  }, []);
  const setPreferences = (preferences: UserPreferences) => {
    const newUser = { ...(user as LoggedUser), preferences };
    setUser(newUser);
    localStorage.setItem("currentUser", JSON.stringify(newUser));
    userService.updatePreferences(
      newUser.preferences.sortCriteria,
      newUser.preferences.sortAscendant,
    );
  };

  if (user === null) {
    return <SpinnerIcon />;
  }
  return (
    <LoggedUserContext.Provider value={{ user, setPreferences }}>
      {children}
    </LoggedUserContext.Provider>
  );
};
