import React from "react";

import { Page } from "../Components/Page";
import { MainMenu } from "../MainMenu";
import { Thumbnail } from "../Utils/Thumbnail";
import { Icon } from "../Utils/Icons";

export const iframeStyle = {
  flex: "1",
  border: "none",
  width: "100%",
  height: "42em",
};

export const IframePage: React.FC<{ title: string; url: string }> = ({
  title,
  url,
}) => {
  return (
    <Page.Content>
      <div className="container">
        <Page.Header className="row">
          <h1 className="col-md-8 col-mdOffset-2 col-12">
            <Thumbnail $size={60}>
              <Icon iconName="user-plus" />
            </Thumbnail>
            <span className="margin-left">{title}</span>
          </h1>
        </Page.Header>
        <div className="row">
          <div className="col-md-2 context-menu">
            <MainMenu />
          </div>
          <div className="col-md-8 managment-content">
            <div className="managment-header" />
            <iframe src={url} style={iframeStyle} />
          </div>
        </div>
      </div>
    </Page.Content>
  );
};
