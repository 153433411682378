import { DateTime } from "luxon";
import { Map } from "immutable";

import { App, AppId } from "./App";
declare const projectIdBrand: unique symbol;
export interface ProjectId extends String {
  [projectIdBrand]: "PROJECT_ID_BRAND";
}
export function castProjectId(id: string): ProjectId {
  return id as unknown as ProjectId;
}

export interface ProjectDescriptor {
  id: ProjectId;
  name: string;
  description: string;
  owner: string;
  creationDate: DateTime;
}

export type ProjectMemberRole = "GUEST" | "MAINTAINER";

export interface ProjectMember {
  login: string;
  role: ProjectMemberRole;
}

export interface Project extends ProjectDescriptor {
  members: Map<string, ProjectMember>;
  apps: Map<AppId, App>;
}

export interface Commit {
  sha: string;
  message: string;
}

export interface Branch {
  name: string;
  commit: Commit;
}
