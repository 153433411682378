import styled from "styled-components";

export const List = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dedede;
  height: 60px;
`;

export const ListItemName = styled.div`
  font-weight: bold;
  margin-left: 10px;
`;

export const ListDescription = styled.div`
  margin: auto;
`;

export const ListCreationDate = styled.div`
  margin-right: 2em;
`;

export const ListItemCol = styled.div`
  flex: 1;
`;
