import React, { ChangeEvent } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import styled from "styled-components";

import {
  ConnectionError,
  RepoServiceContext,
  RepoProvider,
  RepoDescriptor,
} from "../Services/RepoService";
import { assert } from "../Utils/utils";
import { SpinnerIcon } from "../Utils/Icons";
import { RequiredLabel } from "../Components/RequiredLabel";

interface RepoSelectorProps {
  buildService: string;
  repoUrl: string;
  onRepoUrlChange: (repoUrl: string) => void;
  onBuildServiceChange: (buildService: string) => void;
  autoFocus?: boolean;
}

export function RepoSelector({
  buildService,
  repoUrl,
  onBuildServiceChange,
  onRepoUrlChange,
  autoFocus,
}: RepoSelectorProps) {
  const repoService = React.useContext(RepoServiceContext);
  const [repoProviders, setSources] = React.useState<Map<
    string,
    RepoProvider
  > | null>(null);
  React.useEffect(() => {
    repoService.getRepoProviders().then(setSources);
  }, []);
  const [providerLoading, setProviderLoading] = React.useState(false);

  React.useEffect(() => {
    setProviderLoading(false);
  }, [buildService]);

  if (repoProviders === null) {
    return (
      <p>
        Loading integrations
        <SpinnerIcon />
      </p>
    );
  }

  function reloadProvider(providerName: string) {
    setProviderLoading(true);
    repoService
      .getRepoProviders()
      .then((providers) => {
        const provider = providers.get(providerName);
        if (provider && repoProviders) {
          const newProviders = new Map(repoProviders);
          newProviders.set(providerName, provider);
          setSources(newProviders);
        }
      })
      .finally(() => setProviderLoading(false));
  }

  const currentRepoProvider = repoProviders.get(buildService);

  let buildServiceFormPart;
  switch (buildService) {
    case "":
      buildServiceFormPart = (
        <VCSFormPart
          repoUrl={repoUrl}
          onRepoUrlChange={onRepoUrlChange}
          autoFocus={autoFocus}
        />
      );
      break;
    default:
      assert(currentRepoProvider);
      buildServiceFormPart = (
        <VCSSourceFormPart
          repoUrl={repoUrl}
          onRepoUrlChange={onRepoUrlChange}
          repoProvider={currentRepoProvider}
          onProviderReloadRequest={() =>
            reloadProvider(currentRepoProvider.name)
          }
          providerLoading={providerLoading}
        />
      );
  }

  return (
    <>
      <Form.Group>
        <label htmlFor="buildForm">Build from</label>
        <Form.Select
          id="buildForm"
          name="buildService"
          value={buildService}
          onChange={(e) => onBuildServiceChange(e.currentTarget.value)}
        >
          <option value="">Public repository</option>
          {Array.from(repoProviders.entries(), ([sourceUrl, sourceDesc]) => (
            <option key={sourceUrl} value={sourceUrl}>
              Private repository on {sourceUrl} ({sourceDesc.type} integration)
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <Form.Group>{buildServiceFormPart}</Form.Group>
    </>
  );
}

const VCSFormPart: React.FC<{
  repoUrl: string;
  onRepoUrlChange: (repoUrl: string) => void;
  autoFocus?: boolean;
}> = ({ repoUrl, onRepoUrlChange, autoFocus }) => {
  return (
    <div id="vcsForm">
      <Form.Group className="required">
        <RequiredLabel htmlFor="repoUrl">Repo Url</RequiredLabel>
        <Form.Control
          name="repoUrl"
          value={repoUrl}
          onChange={(e) => onRepoUrlChange(e.currentTarget.value)}
          autoFocus={autoFocus}
          required
        />
      </Form.Group>
    </div>
  );
};

const VCSSourceFormPart: React.FC<{
  repoUrl: string;
  onRepoUrlChange: (repoUrl: string) => void;
  repoProvider: RepoProvider;
  onProviderReloadRequest: () => void;
  providerLoading: boolean;
}> = ({
  repoUrl,
  onRepoUrlChange,
  repoProvider,
  onProviderReloadRequest,
  providerLoading,
}) => {
  const [projects, setProjects] = React.useState<RepoDescriptor[] | null>(null);
  const [projectFilter, setProjectFilter] = React.useState("");
  const [isStillConnected, setIsStillConnected] = React.useState(false);
  const [getPrivateProjectMethod, setGetPrivateProjectMethod] =
    React.useState("url");
  React.useEffect(() => {
    if (repoProvider.authorized) {
      setIsStillConnected(true);
      setProjectFilter("");
      setProjects(null);
      repoProvider
        .getRepositories()
        .then((projects) => {
          setProjects(projects);
          if (getPrivateProjectMethod === "projects") {
            if (projects.length > 0) {
              onRepoUrlChange(projects[0].url);
            } else {
              onRepoUrlChange("");
            }
          }
        })
        .catch((error) => {
          if (error instanceof ConnectionError) {
            setIsStillConnected(false);
          }
        });
    }
  }, [repoProvider]);

  React.useEffect(() => {
    // If the provider is not authorized check regularly if the user has
    // authorized it.
    // This is necessary since the provider is authorized on another site
    if (!repoProvider.authorized) {
      const providerUpdateInterval = window.setInterval(() => {
        onProviderReloadRequest();
      }, 5000);
      return () => window.clearInterval(providerUpdateInterval);
    }
  }, [repoProvider.name, repoProvider.authorized]);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const filterValue = e.currentTarget.value.toLowerCase();
    setProjectFilter(filterValue);
    if (projects) {
      const filteredProjects = projects.filter((project) =>
        project.name.toLowerCase().includes(filterValue),
      );
      if (filteredProjects) {
        onRepoUrlChange(filteredProjects[0].url);
      }
    }
  };

  if (!repoProvider.authorized || !isStillConnected) {
    return (
      <Form.Group>
        <a
          type="button"
          href={repoProvider.connectUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-outline-secondary"
        >
          Connect to {repoProvider.name}
        </a>
        {providerLoading && (
          <>
            Checking connection <SpinnerIcon />
          </>
        )}
        <br />
        The project list will appear here once connected.
      </Form.Group>
    );
  }

  const handleSelect = (event: React.FormEvent<HTMLSelectElement>) => {
    const value = event.currentTarget.value;
    onRepoUrlChange(value);
  };

  const handleRepoUrlChange = (event: React.FocusEvent<HTMLInputElement>) => {
    onRepoUrlChange(event.currentTarget.value);
  };

  const handleMethodSelect = (event: React.FormEvent<HTMLSelectElement>) => {
    const value = event.currentTarget.value;
    setGetPrivateProjectMethod(value);
  };

  return (
    <div id="vcsForm">
      <Form.Group>
        <MethodCol sm={4}>
          <Form.Select onChange={handleMethodSelect} defaultValue="url">
            <option key="url" value="url">
              From Project Url
            </option>
            <option key="project" value="project">
              From Projects List
            </option>
          </Form.Select>
        </MethodCol>
      </Form.Group>
      {getPrivateProjectMethod === "url" ? (
        <Form.Group>
          <Form.Control
            value={repoUrl}
            onChange={handleRepoUrlChange}
            required
          />
        </Form.Group>
      ) : (
        <>
          {projects === null ? (
            <LoadingCol>
              Loading projects <SpinnerIcon />
            </LoadingCol>
          ) : (
            <Form.Group>
              <FilterCol sm={4}>
                <Form.Control
                  type="text"
                  placeholder="Project filter"
                  value={projectFilter}
                  onChange={handleSearchChange}
                />
              </FilterCol>
              <Form.Select onChange={handleSelect} value={repoUrl}>
                {projects
                  .filter((project) =>
                    project.name.toLowerCase().includes(projectFilter),
                  )
                  .map((project) => (
                    <option key={project.url} value={project.url}>
                      {project.name}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
          )}
        </>
      )}
    </div>
  );
};

const MethodCol = styled(Col)`
  padding: 8px 0 0 0;
  margin-bottom: 1em;
`;

const LoadingCol = styled(Col)`
  padding: 12px 0 0 0;
  margin: 0 0 4em 15em;
`;

const FilterCol = styled(Col)`
  margin-top: 1em;
  float: right;
  padding: 0;
`;
