import React from "react";
import { useDispatch } from "react-redux";
import Ansi from "ansi-to-react";
import Dropdown from "react-bootstrap/Dropdown";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import { DateTime } from "luxon";

import { App, AppId } from "../Model/App";
import { ProjectId } from "../Model/Project";
import { ProjectServiceContext } from "../Services/ProjectService";
import { useModal } from "../Utils/Modal";
import { Icon, SpinnerIcon } from "../Utils/Icons";
import { appShortcutUrl } from "../Utils/urls";
import { WebServiceId } from "../Model/WebService";

export const AppActions: React.FC<{
  app: App;
  projectId: ProjectId;
  onAppUpdated: () => void;
  checkForUpdates: (app: AppId) => void;
  loadRebuildModal: (app: App) => void;
}> = ({ app, projectId, onAppUpdated, checkForUpdates, loadRebuildModal }) => {
  const projectService = React.useContext(ProjectServiceContext);
  const buildStatus = app.imageSource.buildStatus;
  const dispatch = useDispatch();
  const [showSpinner, setSpinner] = React.useState(false);
  const [logContent, setLogContent] = React.useState("");
  const {
    show: showLogModal,
    open: openLogModal,
    close: closeLogModal,
  } = useModal();
  const {
    show: showDeleteModal,
    open: openDeleteModal,
    close: closeDeleteModal,
  } = useModal();
  const {
    show: showAbortModal,
    open: openAbortModal,
    close: closeAbortModal,
  } = useModal();
  const {
    show: showDetailsModal,
    open: openDetailsModal,
    close: closeDetailsModal,
  } = useModal();

  async function getLog() {
    if (showLogModal !== null) {
      const _content = await projectService.getLogs(app.id);
      setLogContent(_content);
    }
  }

  async function handleLog() {
    openLogModal();
  }

  async function handleLogModalClose() {
    closeLogModal();
  }

  React.useEffect(() => {
    if (showLogModal) {
      getLog();
      const interval = setInterval(getLog, 5000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [showLogModal]);

  async function handleDeleteClicked(appId: AppId) {
    setSpinner(true);
    closeDeleteModal();
    projectService.deleteApp(appId, projectId).then(() => {
      setSpinner(false);
      onAppUpdated();
    });
  }

  async function handleCancelClicked(appId: AppId) {
    closeAbortModal();
    projectService.cancelBuild(appId, projectId).then(() => {
      onAppUpdated();
    });
  }

  async function stopApp(appId: AppId) {
    setSpinner(true);
    projectService.stopApp(appId, projectId).then(() => {
      setSpinner(false);
      onAppUpdated();
    });
  }

  async function handleEditClicked(app: App) {
    dispatch({
      type: "OPEN_MODAL",
      form: {
        type: "APP_EDITION",
        editedApp: app,
      },
    });
  }

  async function handleMoveClicked(app: App) {
    dispatch({
      type: "OPEN_MODAL",
      form: {
        type: "APP_MOVE",
        editedApp: app,
      },
    });
  }

  async function handleDuplicateClicked(app: App) {
    dispatch({
      type: "OPEN_MODAL",
      form: {
        type: "APP_DUPLICATE",
        editedApp: app,
      },
    });
  }

  return (
    <>
      {showSpinner ? (
        <div>
          <SpinnerIcon />
        </div>
      ) : (
        <>
          <Dropdown id={`build-actions-${app.id}`}>
            <DropdownToggle
              variant="link"
              bsPrefix="p-2"
              className="btn btn-info"
              disabled={app.runConfig.serverStatus === "SPAWNING"}
            >
              <Icon iconName="ellipsis-v" />
            </DropdownToggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={openDetailsModal}>Details</Dropdown.Item>
              <Dropdown.Item onClick={() => handleEditClicked(app)}>
                Edit
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => handleMoveClicked(app)}
                disabled={
                  app.runConfig.serverStatus !== "STOP" ||
                  app.imageSource.buildStatus === "PENDING" ||
                  app.imageSource.buildStatus === "BUILDING"
                }
              >
                Move (change project)
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => handleDuplicateClicked(app)}
                disabled={
                  app.runConfig.serverStatus !== "STOP" ||
                  app.imageSource.buildStatus === "PENDING" ||
                  app.imageSource.buildStatus === "BUILDING"
                }
              >
                Duplicate
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={() => handleLog()}>
                Show logs
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => checkForUpdates(app.id)}
                disabled={
                  app.imageSource.buildStatus === "PENDING" ||
                  app.imageSource.buildStatus === "BUILDING"
                }
              >
                Check for updates
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => loadRebuildModal(app)}
                disabled={
                  app.runConfig.serverStatus !== "STOP" ||
                  app.imageSource.buildStatus === "PENDING" ||
                  app.imageSource.buildStatus === "BUILDING"
                }
              >
                Rebuild
              </Dropdown.Item>
              <Dropdown.Divider />
              {app.runConfig.serverStatus === "READY" ? (
                <Dropdown.Item
                  onClick={() => {
                    stopApp(app.id);
                  }}
                >
                  Stop
                </Dropdown.Item>
              ) : app.imageSource.buildStatus === "PENDING" ||
                app.imageSource.buildStatus === "BUILDING" ? (
                <Dropdown.Item onClick={openAbortModal}>Abort</Dropdown.Item>
              ) : (
                <Dropdown.Item onClick={openDeleteModal}>Delete</Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
          <Modal size="lg" show={showDeleteModal} onHide={closeDeleteModal}>
            <Modal.Header closeButton={true}>
              <Modal.Title>Deletion</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h3>
                This will permanently remove <b>{app.displayName}</b>
              </h3>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-danger" onClick={closeDeleteModal}>
                Cancel
              </button>
              <button
                className="btn btn-primary"
                onClick={() => handleDeleteClicked(app.id)}
              >
                Delete
              </button>
            </Modal.Footer>
          </Modal>
          <Modal size="lg" show={showAbortModal} onHide={closeAbortModal}>
            <Modal.Header closeButton={true}>
              <Modal.Title>Abort {app.displayName} build</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h3>Are you sure you want to abort this build ?</h3>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-danger" onClick={closeAbortModal}>
                Cancel
              </button>
              <button
                className="btn btn-primary"
                onClick={() => handleCancelClicked(app.id)}
              >
                Confirm
              </button>
            </Modal.Footer>
          </Modal>
          <Modal size="lg" show={showLogModal} onHide={handleLogModalClose}>
            <Modal.Header closeButton={true}>
              <div className={`badge badge-${buildStatus}`}>
                <Modal.Title>
                  {app.displayName} logs{" "}
                  {(buildStatus === "BUILDING" ||
                    buildStatus === "PENDING") && <SpinnerIcon />}
                </Modal.Title>
              </div>
            </Modal.Header>
            <Modal.Body>
              {logContent ? (
                <pre>
                  <Ansi>{logContent}</Ansi>
                </pre>
              ) : (
                <div>
                  <SpinnerIcon />
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-primary" onClick={handleLogModalClose}>
                Close
              </button>
            </Modal.Footer>
          </Modal>
          <Modal size="lg" show={showDetailsModal} onHide={closeDetailsModal}>
            <Modal.Header closeButton={true}>
              <Modal.Title>
                <b>{app.displayName}</b>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table striped bordered hover>
                <tbody>
                  <tr hidden={true}></tr>
                  <tr>
                    <td>Description</td>
                    <td>{app.desc}</td>
                  </tr>
                  <tr>
                    <td>Creation Date</td>
                    <td>
                      {app.creationDate
                        .setLocale("en-gb")
                        .toLocaleString(DateTime.DATETIME_SHORT)}
                    </td>
                  </tr>
                  <tr>
                    <td>Last Update</td>
                    <td>
                      {app.lastUpdate
                        .setLocale("en-gb")
                        .toLocaleString(DateTime.DATETIME_SHORT)}
                    </td>
                  </tr>
                  <tr>
                    <td>Build Duration</td>
                    <td>
                      {app.imageSource.buildDuration && (
                        <>
                          {Math.floor(app.imageSource.buildDuration / 60)}{" "}
                          minutes
                        </>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Instance Type</td>
                    <td>{app.runConfig.instanceType}</td>
                  </tr>
                  <tr>
                    <td>Volumes</td>
                    <td>
                      <ul>
                        {app.runConfig.volumes.map((vol) => (
                          <li key={vol}>{vol}</li>
                        ))}
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Webservices</td>
                    <td>
                      <ul>
                        {app.runConfig.webServices.map((ws: WebServiceId) => (
                          <li key={ws}>
                            <a href={appShortcutUrl(projectId, app.id, ws)}>
                              {ws}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>App file</td>
                    <td>{app.runConfig.appFileName}</td>
                  </tr>
                  <tr>
                    <td>Extra Packages</td>
                    <td>
                      <ul>{app.imageSource.extraPackages}</ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Repo</td>
                    <td>
                      <a href={app.imageSource.repoChangeset.repoUrl}>
                        {app.imageSource.repoChangeset.repoUrl}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Branch / Changeset</td>
                    <td>
                      <ul>
                        {app.imageSource.repoChangeset.branch && (
                          <li>{app.imageSource.repoChangeset.branch}</li>
                        )}
                        <li>{app.imageSource.repoChangeset.changeset}</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Base Image</td>
                    <td>{app.imageSource.baseImage}</td>
                  </tr>
                </tbody>
              </Table>
              <Modal.Footer>
                <button className="btn btn-primary" onClick={closeDetailsModal}>
                  Close
                </button>
              </Modal.Footer>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
};

const DropdownToggle = styled(Dropdown.Toggle)`
  font-size: 0.9em;
`;
