import React from "react";
import { useSelector } from "react-redux";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import styled from "styled-components";

import {
  ProjectServiceContext,
  AppCreationData,
} from "../Services/ProjectService";
import { WebServiceId } from "../Model/WebService";
import { ProjectDescriptor } from "../Model/Project";
import { AppIcon, castImageId } from "../Model/App";
import { InstanceTypesForm } from "./InstanceTypesForm";
import { RepoSelector } from "./RepoSelector";
import { WebServicesSelector } from "./WebServicesSelector";
import { Icon, SpinnerIcon } from "../Utils/Icons";
import { appFileTooltip } from "../Utils/Tooltips";
import { WizardSteps } from "../Components/WizardSteps";
import { ElementType } from "../Utils/type-helpers";
import { RequiredLabel } from "../Components/RequiredLabel";
import { State } from "../reducer";
import { EditIconForm } from "./EditIconForm";

function useWizard<T extends ElementType<readonly string[]>>(
  steps: readonly T[],
) {
  const [stepIdx, setStepIdx] = React.useState(0);
  function back() {
    setStepIdx(stepIdx - 1);
  }
  function next() {
    setStepIdx(stepIdx + 1);
  }

  return {
    currentStepIndex: stepIdx,
    currentStep: steps[stepIdx],
    back,
    next,
    hasNextStep: stepIdx < steps.length - 1,
    hasPreviousStep: stepIdx > 0,
  };
}

export const CreateAppForm: React.FC<{
  show: boolean;
  onAppCreated: () => void;
  projectSource: ProjectDescriptor;
  onCancel: () => void;
}> = ({ show, onAppCreated, projectSource, onCancel }) => {
  const projectService = React.useContext(ProjectServiceContext);
  const wizard = useWizard(["REPO", "METADATA", "SERVICES", "ADVANCED"]);
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [icon, setIcon] = React.useState<AppIcon>({
    startColor: "skyblue",
    stopColor: "springGreen",
    imgUrl: "",
  });
  const [buildService, setBuildService] = React.useState("");
  const [repoUrl, setRepoUrl] = React.useState("");
  const [volumes, setVolumes] = React.useState<string[]>([]);
  const [buildType, setBuildType] = React.useState("branch");
  const [branch, setBranch] = React.useState("master");
  const [changeset, setChangeset] = React.useState("");
  const [useRepoDocker, setUseRepoDocker] = React.useState(false);
  const toggleUseRepoDocker = () =>
    setUseRepoDocker((_useRepoDocker) => !_useRepoDocker);
  const [webService, setWebService] = React.useState<WebServiceId>("lab");
  const [appFileName, setAppFileName] = React.useState("");
  const [extraPackages, setExtraPackages] = React.useState("");
  const [instanceType, setInstanceType] = React.useState("default");
  const toggleService = (service: WebServiceId) => {
    setWebService(service);
  };
  const [creating, setCreating] = React.useState(false);
  const projectApps = useSelector((state: State) => state.apps);
  const [baseImage, setBaseImage] = React.useState("");
  const [availableVolumes, setAvailableVolumes] = React.useState<{
    [key: string]: string;
  }>({});
  const [errorMsg, setErrorMsg] = React.useState<string | null>(null);

  React.useEffect(() => {
    projectService.getVolumes().then(setAvailableVolumes);
  }, []);
  const handleVolumesChange = (event: React.FormEvent<HTMLSelectElement>) => {
    setVolumes(
      Array.from(event.currentTarget.options)
        .filter((o) => o.selected)
        .map((o) => o.value),
    );
  };

  const defaultAppFileName = ["voila", "panel"].includes(webService)
    ? "index.ipynb"
    : webService === "streamlit"
      ? "index.py"
      : "";

  const handleBuildChange = (value: string) => {
    if (buildType === "branch") {
      setBranch(value);
      setChangeset("");
    } else {
      setBranch("");
      setChangeset(value);
    }
  };

  let currentScreen = null;
  let isStepValid = true;
  switch (wizard.currentStep) {
    case "REPO":
      currentScreen = (
        <>
          <RepoSelector
            buildService={buildService}
            repoUrl={repoUrl}
            onBuildServiceChange={setBuildService}
            onRepoUrlChange={setRepoUrl}
            autoFocus
          />
          <br />
          <details>
            <summary>Advanced options</summary>
            <Form.Group className="mb-3">
              <Form.Check
                type="radio"
                label="Branch"
                checked={buildType === "branch"}
                onChange={() => setBuildType("branch")}
              ></Form.Check>
              <Form.Check
                type="radio"
                label="Changeset"
                checked={buildType === "changeset"}
                onChange={() => setBuildType("changeset")}
              ></Form.Check>
              <Form.Control
                name="rebuildChangeset"
                onChange={(e) => handleBuildChange(e.currentTarget.value)}
              />
              <Form.Check
                type="checkbox"
                label="Use repo Dockerfile"
                name="useRepoDocker"
                checked={useRepoDocker}
                onChange={toggleUseRepoDocker}
              />
            </Form.Group>
          </details>
        </>
      );
      isStepValid = repoUrl.length > 0;
      break;
    case "METADATA":
      currentScreen = (
        <>
          <Form.Group className="mb-3">
            <RequiredLabel htmlFor="name">App Name</RequiredLabel>
            <Form.Control
              name="name"
              value={name}
              onChange={(e) => setName(e.currentTarget.value)}
              minLength={3}
              required
              autoFocus
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>App Description</Form.Label>
            <Form.Control
              as="textarea"
              name="description"
              value={description}
              onChange={(e) => setDescription(e.currentTarget.value)}
              rows={2}
            />
            <br />
          </Form.Group>
          <details>
            <summary>Icon</summary>
            <EditIconForm
              appName={name}
              appIcon={icon}
              handleIconChange={setIcon}
            />
          </details>
        </>
      );
      isStepValid = name.length >= 3;
      break;
    case "SERVICES":
      currentScreen = (
        <>
          <WebServicesSelector
            availableWebServices={[
              "lab",
              "voila",
              "panel",
              "streamlit",
              "vnc",
              "vnclab",
            ]}
            webService={webService}
            onWebServiceClick={toggleService}
            autoFocus
          />
          <br />
          <details>
            <summary>Advanced Options</summary>
            <AdvancedOptions>
              {["voila", "panel"].includes(webService) && (
                <>
                  <h4>
                    Change the App default filename{" "}
                    <OverlayTrigger placement="right" overlay={appFileTooltip}>
                      <span>
                        <Icon iconName="question-circle-o" />
                      </span>
                    </OverlayTrigger>
                  </h4>
                  <Form.Control
                    name="appFileName"
                    value={appFileName}
                    placeholder={defaultAppFileName}
                    onChange={(e) => setAppFileName(e.currentTarget.value)}
                    minLength={3}
                  />
                </>
              )}
              <h5>Use an App as Base Image</h5>
              <Form.Select
                value={baseImage}
                onChange={(e) => setBaseImage(e.currentTarget.value)}
              >
                <option key="noBaseImage" value=""></option>
                {Array.from(projectApps, ([appId, app]) => (
                  <option
                    key={appId.toString()}
                    value={app.imageSource.id.toString()}
                  >
                    {app.project.name} / {app.displayName}
                  </option>
                ))}
              </Form.Select>
              <h5>Add Extra Packages</h5>
              <p>
                Write, separated by a space, the packages you want to install
                with <b>apt-get</b>.
              </p>
              <Form.Control
                as="textarea"
                name="extraPackages"
                value={extraPackages}
                onChange={(e) => setExtraPackages(e.currentTarget.value)}
                rows={1}
              />
            </AdvancedOptions>
          </details>
        </>
      );
      break;
    case "ADVANCED":
      currentScreen = (
        <>
          <InstanceTypesForm
            instanceType={instanceType}
            onInstanceTypeChange={setInstanceType}
          />
          {availableVolumes && (
            <details>
              <summary>Add Extra Volumes</summary>
              <AdvancedOptions>
                <Form.Select
                  multiple
                  value={volumes || []}
                  onChange={handleVolumesChange}
                >
                  {Array.from(
                    Object.entries(availableVolumes),
                    ([volume, mount]) => (
                      <option key={volume} value={volume}>
                        {volume} [{mount}]
                      </option>
                    ),
                  )}
                </Form.Select>
                <Icon iconName="info-circle" />{" "}
                <em>Use [Ctrl-click] to deselect a volume</em>
              </AdvancedOptions>
            </details>
          )}
        </>
      );
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (wizard.hasNextStep) {
      if (isStepValid) {
        wizard.next();
      }
      return;
    }
    const appData: AppCreationData = {
      name,
      description,
      icon,
      instanceType,
      webService,
      appFileName: (appFileName || defaultAppFileName).trim(),
      volumes: volumes || [],
      // Image creation data
      buildService,
      repoUrl,
      branch,
      changeset,
      useRepoDocker,
      notebookImageId: castImageId(baseImage) || null,
      extraPackages,
    };
    setCreating(true);
    projectService
      .createApp(appData, projectSource.id)
      .then(async (response) => {
        setCreating(false);
        if (response.ok) {
          onAppCreated();
        } else {
          const data = await response.json();
          setErrorMsg(data.reason);
        }
      });
  };

  return (
    <Modal show={show} onHide={onCancel} backdrop="static">
      <Modal.Header closeButton={true}>
        <Modal.Title>
          Create App in &quot;{projectSource.name}&quot;
        </Modal.Title>
        {errorMsg && (
          <Alert variant="danger" onClose={() => setErrorMsg(null)}>
            {errorMsg}
          </Alert>
        )}
      </Modal.Header>
      <form
        method="get"
        role="form"
        onSubmit={handleSubmit}
        key={wizard.currentStep}
      >
        <WizardSteps
          steps={["Source Repository", "Metadata", "Services", "Platform"]}
          activeStep={wizard.currentStepIndex}
        />
        <Modal.Body>{currentScreen}</Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            onClick={onCancel}
            className="btn btn-danger"
            disabled={creating}
          >
            Cancel
          </button>
          {wizard.hasNextStep ? (
            <button className="btn btn-primary">Next</button>
          ) : (
            <button className="btn btn-primary" disabled={creating}>
              {creating ? (
                <>
                  Creating&nbsp;
                  <SpinnerIcon />
                </>
              ) : (
                "Create"
              )}
            </button>
          )}
          {wizard.hasPreviousStep && (
            <button
              type="button"
              className="btn btn-link pull-left"
              disabled={creating}
              onClick={wizard.back}
            >
              Back
            </button>
          )}
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const AdvancedOptions = styled.div`
  margin: 10px;
`;
