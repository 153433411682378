import React from "react";
import Form from "react-bootstrap/Form";

import { WebServiceId, WEB_SERVICES } from "../Model/WebService";

interface WebServicesSelectorProps {
  availableWebServices: WebServiceId[];
  webService: WebServiceId;
  onWebServiceClick: (webService: WebServiceId) => void;
  autoFocus?: boolean;
}

export function WebServicesSelector({
  availableWebServices,
  webService,
  onWebServiceClick,
  autoFocus,
}: WebServicesSelectorProps) {
  return (
    <div className="form-group">
      {availableWebServices.map((webServiceId, idx) => {
        const { name, description } = WEB_SERVICES[webServiceId];
        return (
          <div className="radio" key={webServiceId} title={description}>
            <Form.Check
              type="radio"
              label={name}
              autoFocus={autoFocus && idx === 0}
              name={webServiceId}
              checked={webService === webServiceId}
              onChange={() => onWebServiceClick(webServiceId)}
            ></Form.Check>
          </div>
        );
      })}
    </div>
  );
}
