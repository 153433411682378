import React from "react";
import { DateTime } from "luxon";

export interface Pod {
  name: string;
  appName: string;
  userName: string;
  serverName: string;
  status: string;
  startDate: DateTime;
  lastUpdate: DateTime;
  nodeName: string;
}

export interface Node {
  name: string;
  id: string;
  cpuCount: number;
  status: string;
}

export interface NodePool {
  id: string;
  status: "ADD_NODE" | "REMOVE_NODE" | "READY";
  nodes: string[];
}

export interface KubeService {
  getPods(): Promise<Pod[]>;
  getNodes(): Promise<Node[]>;
  getNodepool(): Promise<NodePool | undefined>;
  updateNodepool(
    desiredNodes: number,
    nodesToRemove: string[],
  ): Promise<Response>;
}

export const KubeServiceContext = React.createContext<KubeService>({
  async getPods() {
    const response = await fetch(`/services/repo2img/api/kube/pods`);
    if (response.ok) {
      const data = await response.json();
      const pods = data.pods.map((pod: any) => ({
        ...pod,
        startDate: DateTime.fromISO(pod.startDate),
        lastUpdate: DateTime.fromISO(pod.lastUpdate),
      }));
      return pods;
    } else {
      console.error(response);
    }
  },

  async getNodes() {
    const response = await fetch(`/services/repo2img/api/kube/nodes`);
    if (response.ok) {
      const data = await response.json();
      return data.nodes;
    } else {
      console.error(response);
    }
  },

  async getNodepool() {
    const response = await fetch(`/services/repo2img/api/kube/nodepool`);
    if (response.ok) {
      const data = await response.json();
      return data.nodepool;
    } else {
      console.error(response);
    }
  },

  async updateNodepool(desiredNodes, nodesToRemove) {
    const response = await fetch(`/services/repo2img/api/kube/nodepool`, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify({ desiredNodes, nodesToRemove }),
    });
    if (response.ok) {
      const data = await response.json();
      return data.nodes;
    } else {
      console.error(response);
    }
  },
});
