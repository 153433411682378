import React from "react";
import styled from "styled-components";
import Tooltip from "react-bootstrap/Tooltip";

const HelpTooltip = styled(Tooltip)`
  .tooltip-inner {
    text-align: left;
    background: whitesmoke;
    max-width: 100%;
    color: black;
  }
`;

export const appFileTooltip = (
  <HelpTooltip>
    <section>
      A file path must be provided for some webservices. <br />
      This path must be relative to the root of the repository.
      <br /> By default:
      <ul>
        <li> Voila uses a notebook file named index.ipynb</li>
        <li> Streamlit uses a python file named index.py</li>
      </ul>
    </section>
  </HelpTooltip>
);
