import React from "react";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { useDispatch } from "react-redux";
import { DateTime } from "luxon";
import { Map, Set } from "immutable";
import styled from "styled-components";

import { App, AppId } from "../Model/App";
import { ProjectId } from "../Model/Project";
import { WebServiceId } from "../Model/WebService";
import { ProjectServiceContext } from "../Services/ProjectService";
import { RepoServiceContext } from "../Services/RepoService";
import { ServerControl } from "../Utils/ServerControl";
import { useModalWithData } from "../Utils/Modal";
import { SpinnerIcon, RefreshIcon } from "../Utils/Icons";
import { AppActions } from "./AppActions";
import { RebuildModal, RebuildData } from "./RebuildModal";

export const UpdatesInformations: React.FC<{
  app: App;
  spinner: boolean;
  loadRebuildModal: (app: App) => void;
}> = ({ app, spinner, loadRebuildModal }) => {
  return (
    <>
      {spinner && <SpinnerIcon />}
      {app.imageSource.buildStatus !== "BUILDING" &&
        app.imageSource.buildStatus !== "PENDING" &&
        !spinner &&
        app.imageSource.availableUpdates && (
          <Button
            title="Rebuild your image (updates are available)"
            size="sm"
            className="refresh-WARNING"
            onClick={() => loadRebuildModal(app)}
          >
            <RefreshIcon />
          </Button>
        )}
    </>
  );
};

export const AppsTable: React.FC<{
  apps: Map<AppId, App>;
  projectId: ProjectId;
  onAppUpdated: () => void;
}> = ({ apps, projectId, onAppUpdated }) => {
  const dispatch = useDispatch();
  const projectService = React.useContext(ProjectServiceContext);
  const repoService = React.useContext(RepoServiceContext);
  const [checkingAppsUpdates, setCheckingAppsUpdates] = React.useState(Set());
  const [loadingModal, setLoadingModal] = React.useState(Set());
  const [errorMessage, setErrorMessage] = React.useState("");
  const {
    data: rebuildData,
    show: showRebuildModal,
    open: openRebuildModal,
    close: closeRebuildModal,
  } = useModalWithData<RebuildData>();

  const loadRebuildModal = async (app: App) => {
    setLoadingModal(loadingModal.add(app.id.toString()));
    const repoChangeset = app.imageSource.repoChangeset;
    let [branches, commit] = await Promise.all([
      repoService.getRepoBranches(repoChangeset.repoUrl),
      repoService.getRepoCommit(repoChangeset.repoUrl, repoChangeset.changeset),
    ]);
    let message = "";
    if (!branches) {
      message = `The remote repository information could not be retrieved.
         Maybe your token has expired or this repository is not fully supported`;
      commit = {
        sha: app.imageSource.repoChangeset.changeset,
        message: "",
      };
      branches = [
        {
          name: app.imageSource.repoChangeset.branch,
          commit: commit,
        },
      ];
    }
    openRebuildModal({
      app,
      projectId: projectId,
      branches: branches,
      currentCommit: commit,
      warningMessage: message,
    });
    setLoadingModal(loadingModal.delete(app.id.toString()));
  };

  const checkForUpdates = (appId: AppId) => {
    setCheckingAppsUpdates(checkingAppsUpdates.add(appId.toString()));
    projectService.checkForUpdates(appId).then((response) => {
      setCheckingAppsUpdates(checkingAppsUpdates.delete(appId.toString()));
      if (response.ok) {
        onAppUpdated();
      }
    });
  };

  function handleStartClicked(appId: AppId, mode: WebServiceId) {
    projectService.startApp(appId, projectId, mode).then(async (response) => {
      const data = await response.json();
      if (response.ok) {
        window.location.href = data.url;
      } else {
        console.error(data.reason);
        setErrorMessage(data.reason);
      }
    });
    dispatch({
      type: "CHANGE_APP_SERVER_STATUS",
      appId: appId,
      status: "SPAWNING",
    });
  }

  return (
    <div>
      {errorMessage && (
        <Alert variant="danger" onClose={() => setErrorMessage("")}>
          {errorMessage}
        </Alert>
      )}
      {!apps.isEmpty() ? (
        <TableContainer>
          <Table hover>
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col">Name</th>
                <th scope="col">Description</th>
                <th scope="col">Last Update</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {Array.from(apps, ([appId, app]) => (
                <tr key={appId.toString()} style={{ height: "1em" }}>
                  <td style={{ width: "12%" }}>
                    <ServerControl
                      runnable={app}
                      buildStatus={app.imageSource.buildStatus}
                      onStart={(mode) => handleStartClicked(app.id, mode)}
                    />
                  </td>
                  <td style={{ width: "5%" }}>
                    {app.imageSource.buildStatus === "BUILDING" ? (
                      <SpinnerIcon />
                    ) : (
                      <UpdatesInformationsDiv>
                        <UpdatesInformations
                          app={app}
                          spinner={
                            checkingAppsUpdates.includes(app.id.toString()) ||
                            loadingModal.includes(app.id.toString())
                          }
                          loadRebuildModal={loadRebuildModal}
                        />
                      </UpdatesInformationsDiv>
                    )}
                  </td>
                  <td>{app.displayName}</td>
                  <td style={{ width: "40%" }}>
                    <Description>{app.desc}</Description>
                  </td>
                  <td>
                    {app.lastUpdate
                      .setLocale("en-gb")
                      .toLocaleString(DateTime.DATETIME_SHORT)}
                  </td>
                  <td>
                    <AppActions
                      app={app}
                      projectId={projectId}
                      onAppUpdated={onAppUpdated}
                      checkForUpdates={checkForUpdates}
                      loadRebuildModal={loadRebuildModal}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {rebuildData && (
            <RebuildModal
              rebuildData={rebuildData}
              show={showRebuildModal}
              closeModal={closeRebuildModal}
              reloadModal={() => loadRebuildModal(rebuildData.app)}
              onAppUpdated={onAppUpdated}
            />
          )}
        </TableContainer>
      ) : (
        <AlertNoApp variant="warning">
          <h4>There is no app in this project yet</h4>
        </AlertNoApp>
      )}
    </div>
  );
};

const TableContainer = styled.section`
  width: 100%;
  padding-left: 1em;
  height: 600px;
  overflow-y: auto;
`;

const UpdatesInformationsDiv = styled.div`
  color: #e0a800;
  width: 5%;
`;

const Description = styled.pre`
  white-space: pre-wrap;
  margin-bottom: 0;
  border: None;
  font-family: inherit;
  overflow: auto;
  width: 25em;
`;

const AlertNoApp = styled(Alert)`
  margin-top: 3em;
`;
