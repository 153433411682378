import React from "react";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import { App, AppId } from "../Model/App";
import { ProjectId, Branch, Commit } from "../Model/Project";
import { ProjectServiceContext } from "../Services/ProjectService";
import { RepoServiceContext, RepoProvider } from "../Services/RepoService";

export interface RebuildData {
  app: App;
  projectId: ProjectId;
  branches: Branch[];
  currentCommit: Commit;
  warningMessage: string;
}
type TypeOfRebuild = "branch" | "currentChangeset" | "customChangeset";

export const RebuildModal: React.FC<{
  rebuildData: RebuildData;
  show: boolean;
  closeModal: () => void;
  reloadModal: () => void;
  onAppUpdated: () => void;
}> = ({ rebuildData, show, closeModal, reloadModal, onAppUpdated }) => {
  const projectService = React.useContext(ProjectServiceContext);
  const repoService = React.useContext(RepoServiceContext);
  const [typeOfRebuild, setTypeOfRebuild] =
    React.useState<TypeOfRebuild>("branch");

  const [rebuildChangeset, setRebuildChangeset] = React.useState("");
  const [rebuildBranch, setRebuildBranch] = React.useState("");
  const [isConnected, setIsConnected] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [repoProvider, setRepoProvider] = React.useState<
    RepoProvider | undefined
  >(undefined);

  React.useEffect(() => {
    if (rebuildData && rebuildData.branches.length > 0) {
      setRebuildBranch(rebuildData.branches[0].name);
      setRebuildChangeset(rebuildData.branches[0].commit.sha);
    }
  }, [rebuildData]);

  if (rebuildData.warningMessage && repoProvider === undefined) {
    repoService.getRepoProviders().then((repos) => {
      const repoURL =
        rebuildData.app.imageSource.repoChangeset.repoUrl.split("/")[2];
      const repo = repos.get(repoURL);
      if (repo) {
        setRepoProvider(repo);
        setIsConnected(false);
      }
    });
  }

  function connectToRepoProviderOrReload(url: string) {
    if (isConnected) {
      reloadModal();
    } else {
      window.open(url, "_blank");
      setIsConnected(true);
    }
  }

  const handleRebuildChange = (
    typeOfRebuild: TypeOfRebuild,
    branch: string,
    changeset: string,
  ) => {
    setTypeOfRebuild(typeOfRebuild);
    setRebuildBranch(branch);
    if (typeOfRebuild === "branch" && !changeset) {
      changeset = rebuildData.branches.filter((br) => br.name === branch)[0]
        .commit.sha;
    }
    setRebuildChangeset(changeset);
  };

  const handleRebuildClicked = (appId: AppId) => {
    projectService
      .rebuild(appId, rebuildData.projectId, rebuildBranch, rebuildChangeset)
      .then(async (response) => {
        if (response.ok) {
          setErrorMessage(null);
          closeModal();
          onAppUpdated();
        } else {
          const data = await response.json();
          console.error(data.reason);
          setErrorMessage(data.reason);
        }
      });
  };

  let currentChangeset = "master";
  if (rebuildData) {
    currentChangeset = rebuildData.app.imageSource.repoChangeset.changeset;
  }

  return (
    <Modal show={show} onHide={closeModal}>
      <Modal.Header closeButton={true}>
        <Modal.Title>
          Rebuild{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href={rebuildData.app.imageSource.repoChangeset.repoUrl}
          >
            {rebuildData.app.displayName}
          </a>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {rebuildData.warningMessage && (
          <>
            <Alert variant="warning">{rebuildData.warningMessage}</Alert>
            {repoProvider && (
              <a
                type="button"
                onClick={() =>
                  connectToRepoProviderOrReload(repoProvider.connectUrl)
                }
                rel="noopener noreferrer"
                className="btn btn-outline-secondary"
              >
                {isConnected
                  ? "Reload Modal"
                  : `Connect to ${repoProvider.name}`}
              </a>
            )}
          </>
        )}
        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
        <div className="form-group">
          <div className="radio" key="branch">
            <Form.Check
              type="radio"
              checked={typeOfRebuild === "branch"}
              label="Branch"
              onChange={() =>
                handleRebuildChange(
                  "branch",
                  rebuildData.branches[0].name,
                  rebuildData.branches[0].commit.sha,
                )
              }
            ></Form.Check>
          </div>
          {typeOfRebuild === "branch" && (
            <select
              className="form-control"
              defaultValue={rebuildBranch}
              onChange={(e) =>
                handleRebuildChange(
                  "branch",
                  e.currentTarget.value.split(",")[0],
                  "",
                )
              }
            >
              {rebuildData.branches.map((branch) => (
                <option key={branch.name} value={branch.name}>
                  {branch.name}
                </option>
              ))}
            </select>
          )}
          {rebuildData.currentCommit && (
            <div
              className="radio"
              key="current"
              title={rebuildData.currentCommit.message}
            >
              <Form.Check
                type="radio"
                checked={typeOfRebuild === "currentChangeset"}
                label={`Current changeset (${currentChangeset})`}
                onChange={() =>
                  handleRebuildChange(
                    "currentChangeset",
                    "",
                    rebuildData.currentCommit.sha,
                  )
                }
              ></Form.Check>
            </div>
          )}
          <div className="radio" key="custom" title="custom changeset">
            <Form.Check
              type="radio"
              checked={typeOfRebuild === "customChangeset"}
              label="Custom"
              onChange={() => handleRebuildChange("customChangeset", "", "")}
            ></Form.Check>
          </div>
        </div>
        {typeOfRebuild === "customChangeset" && (
          <Form.Control
            name="rebuildChangeset"
            onChange={(e) =>
              handleRebuildChange("customChangeset", "", e.currentTarget.value)
            }
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <button type="button" onClick={closeModal} className="btn btn-danger">
          Cancel
        </button>
        <button
          className="btn btn-primary"
          onClick={() => handleRebuildClicked(rebuildData.app.id)}
        >
          Rebuild
        </button>
      </Modal.Footer>
    </Modal>
  );
};
