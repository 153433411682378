export const WEB_SERVICES = {
  lab: {
    name: "JupyterLab",
    description: "Jupyterlab environment",
  },
  voila: {
    name: "Voila",
    description: "Run a notebook in interactive mode with voila",
  },
  panel: {
    name: "Panel",
    description: "Run a notebook in interactive mode with panel",
  },
  streamlit: {
    name: "Streamlit",
    description: "Run an interface for a python script",
  },
  vnc: {
    name: "VNC",
    description: "View desktop",
  },
  vnclab: {
    name: "VNC & Jupyterlab",
    description: "View desktop and Jupyterlab environnement",
  },
} as const;

export type WebServiceId = keyof typeof WEB_SERVICES;
