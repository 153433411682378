import styled from "styled-components";

export const Thumbnail = styled.div.attrs<{ $size: number }>((props) => ({
  $size: props.$size || 40,
}))`
  width: ${(props) => props.$size}px;
  height: ${(props) => props.$size}px;
  border-radius: 100%;
  background-color: #e7f1f9;
  font-size: ${(props) => props.$size / 2}px;
  color: #76889e;
  display: flex;
  justify-content: center;
  align-items: center;
`;
