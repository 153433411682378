import React from "react";
import { DateTime } from "luxon";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { JupyterhubServiceContext } from "../Services/JupyterhubService";
import { Page } from "../Components/Page";
import { MainMenu } from "../MainMenu";
import { MainTabStyle } from "../Utils/MainTab";
import { Thumbnail } from "../Utils/Thumbnail";
import { Icon } from "../Utils/Icons";
import {
  Node,
  NodePool,
  Pod,
  KubeServiceContext,
} from "../Services/KubeService";
import { iframeStyle } from "./IframePage";

export const KubePage: React.FC = ({}) => {
  const kubeService = React.useContext(KubeServiceContext);
  const jhubService = React.useContext(JupyterhubServiceContext);
  const [pods, setPods] = React.useState<Pod[]>([]);
  const [nodes, setNodes] = React.useState<Node[]>([]);
  const [nodePool, setNodePool] = React.useState<null | NodePool>(null);
  const [needsPoolUpdate, setNeedsPoolUpdate] = React.useState(false);
  const [deleting, setDeleting] = React.useState<string[]>([]);
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = React.useState(
    document.location.hash.substring(1),
  );

  const handleTabSelect = (tabName: any) => {
    setSelectedTab(tabName);
    navigate(`#${tabName}`);
    if (tabName === "authArea") {
      const iframe = document.getElementById("jupyterhub_iframe");
      (iframe as any).contentWindow.location.reload(true);
    }
  };

  const tabK8s = (
    <>
      <Icon iconName="cube" />
      <span className="margin-left">K8s</span>
    </>
  );
  const tabServer = (
    <>
      <Icon iconName="cogs" />
      <span className="margin-left">Servers</span>
    </>
  );

  const updateNodes = () => {
    kubeService.getNodes().then((n) => {
      if (n) setNodes(n);
    });
  };

  const updatePods = () => {
    kubeService.getPods().then((p) => {
      if (p) setPods(p);
    });
  };

  const getNodepool = () => {
    kubeService.getNodepool().then((np) => {
      if (!np) {
        setNeedsPoolUpdate(false);
        return;
      }
      setNodePool(np);
      if (np.status === "READY") {
        setNeedsPoolUpdate(false);
        updateNodes();
      } else {
        setNeedsPoolUpdate(true);
      }
    });
  };

  React.useEffect(() => {
    updatePods();
    const interval = setInterval(updatePods, 10000);
    return function cleanup() {
      clearInterval(interval);
    };
  }, []);

  React.useEffect(() => {
    updateNodes();
    const interval = setInterval(updateNodes, 50000);
    return function cleanup() {
      clearInterval(interval);
    };
  }, []);

  React.useEffect(() => {
    getNodepool();
    if (nodePool && needsPoolUpdate) {
      const interval = setInterval(getNodepool, 10000);
      return function cleanup() {
        clearInterval(interval);
      };
    }
  }, [needsPoolUpdate]);

  const addNode = () => {
    kubeService.updateNodepool(nodes.length, []);
    setNeedsPoolUpdate(true);
  };

  const removeNode = (nodeId: string) => {
    if (nodePool) {
      kubeService.updateNodepool(nodePool.nodes.length - 1, [nodeId]);
      setNeedsPoolUpdate(true);
    }
  };

  function stopServer(userName: string, serverName: string) {
    if (deleting.includes(serverName)) return;
    setDeleting([...deleting, serverName]);
    jhubService.stopServer(userName, serverName).then(() => {
      const dd = deleting.filter((s) => s !== serverName);
      setDeleting(dd);
    });
  }

  return (
    <Page.Content>
      <div className="container">
        <Page.Header className="row">
          <h1 className="col-md-8 col-mdOffset-2 col-12">
            <Thumbnail $size={60}>
              <Icon iconName="cube" />
            </Thumbnail>
            <span className="margin-left">Kubernetes</span>
          </h1>
        </Page.Header>
        <div className="row">
          <div className="col-md-2 context-menu">
            <MainMenu />
          </div>
          <div className="col-md-8 managment-content">
            <div className="managment-header" />
            <Tabs
              activeKey={selectedTab ? selectedTab : "k8s"}
              onSelect={(e) => handleTabSelect(e)}
              transition={false}
              id="project-tab"
            >
              <Tab style={MainTabStyle} eventKey="k8s" title={tabK8s}>
                <h3>Nodes</h3>
                {nodePool && (
                  <AddNodebutton
                    disabled={needsPoolUpdate}
                    title="An hourly rate node will be deployed"
                    className="btn btn-primay"
                    variant="light"
                    onClick={addNode}
                  >
                    Add Node
                  </AddNodebutton>
                )}
                <Table responsive="sm">
                  <thead>
                    <tr>
                      <th scope="col">Server Name</th>
                      <th scope="col">Availlable cpus</th>
                      <th scope="col">Status</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {nodes.map((n: Node) => (
                      <tr key={n.name}>
                        <td>{n.name}</td>
                        <td>{n.cpuCount}</td>
                        <td>{n.status}</td>
                        {nodePool && nodePool.nodes.includes(n.name) && (
                          <td>
                            <AddNodebutton
                              onClick={() => removeNode(n.name)}
                              disabled={needsPoolUpdate}
                              className="btn btn-primay"
                            >
                              Delete
                            </AddNodebutton>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {nodePool && nodePool.status === "ADD_NODE" && (
                  <Alert variant="warning">
                    <i
                      className="fa fa-spinner fa-pulse fa-fw"
                      aria-hidden="true"
                    ></i>
                    waiting for node addition ...
                    <p>
                      <strong>
                        An hourly rate node will be deployed. Please turn it off
                        if it is no longer needed !
                      </strong>
                    </p>
                  </Alert>
                )}
                {nodePool && nodePool.status === "REMOVE_NODE" && (
                  <Alert variant="warning">
                    <i
                      className="fa fa-spinner fa-pulse fa-fw"
                      aria-hidden="true"
                    ></i>
                    waiting for node deletion
                  </Alert>
                )}
                <h3>User Pods</h3>
                <Table>
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">App</th>
                      <th scope="col">User</th>
                      <th scope="col">Status</th>
                      <th scope="col">Start date</th>
                      <th scope="col">Last Activity</th>
                      <th scope="col">Node name</th>
                      <th scope="col">Server</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pods.map((p: Pod) => (
                      <tr key={p.name}>
                        <td>{p.name}</td>
                        <td>{p.appName}</td>
                        <td>{p.userName}</td>
                        <td>{p.status}</td>
                        <td>
                          {p.startDate
                            .setLocale("en-gb")
                            .toLocaleString(DateTime.DATETIME_SHORT)}
                        </td>
                        <td>
                          {p.lastUpdate
                            .setLocale("en-gb")
                            .toLocaleString(DateTime.DATETIME_SHORT)}
                        </td>
                        <td>{p.nodeName}</td>
                        {p.status === "Running" &&
                        !deleting.includes(p.serverName) ? (
                          <td>
                            <a
                              role="button"
                              href={`/access/user/${p.userName}/server/${p.serverName}`}
                              className="btn btn-primary"
                            >
                              {"Access"}
                            </a>{" "}
                            <Button
                              variant="danger"
                              onClick={() =>
                                stopServer(p.userName, p.serverName)
                              }
                            >
                              {`Stop`}
                            </Button>
                          </td>
                        ) : (
                          <td>
                            <div>
                              <i
                                className="fa fa-spinner fa-pulse fa-fw"
                                aria-hidden="true"
                              ></i>
                              {p.status === "Running" ? "DELETING" : "PENDING"}
                            </div>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Tab>
              <Tab style={MainTabStyle} eventKey="servers" title={tabServer}>
                <iframe
                  id="jupyterhub_iframe"
                  src="/hub/admin"
                  style={iframeStyle}
                />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </Page.Content>
  );
};

const AddNodebutton = styled(Button)`
  float: right;
  margin: 1em;
`;
