import { DateTime } from "luxon";
import { FileData } from "@aperturerobotics/chonky";
import { ProjectFileService } from "../ProjectFileService";
import { mapFromArray } from "../../Utils/utils";
import { Folder } from "../../Model/Browser";

function createFile(file: Partial<FileData> = {}) {
  return {
    key: "toto.png",
    size: 12,
    modified: DateTime.local().plus({ hours: -1 }).offset,
    ...file,
  };
}

export class FakeProjectFileService implements ProjectFileService {
  private files = mapFromArray("key", [
    createFile({ key: "photos/" }),
    createFile({ key: "photos/animals/cat in a hat.png" }),
    createFile({ key: "photos/animals/kitten_ball.png" }),
    createFile({
      key: "photos/animals/elephants.png",
      modified: +DateTime.local().plus({ hours: -3 }).offset,
    }),
    createFile({ key: "photos/holiday.jpg", size: 8 }),
    createFile({ key: "documents/letter chunks.doc" }),
  ]);

  async getProjectFiles(): Promise<Folder> {
    return {
      files: [
        { id: "file1", name: "file1.txt" },
        { id: "file2", name: "file2.png" },
      ],
      folderChain: [
        {
          id: "dir1",
          name: "dir1",
          isDir: true,
        },
        { id: "dir2", name: "dir2", isDir: true },
      ],
    };
  }

  downloadFile() {
    alert("Not implemented");
  }

  async uploadFile() {
    const key = "photos/animals/dog.png";
    this.files = this.files.set(
      key,
      createFile({ key, size: 4, modified: +DateTime.local().offset }),
    );
    return new Response(null, { status: 204, statusText: "OK" });
  }

  async deleteFile(projectId: string, path: string) {
    this.files = this.files.delete(path);
    return new Response(null, { status: 204, statusText: "OK" });
  }

  async createFolder(projectId: string, path: string) {
    this.files = this.files.set(path, {
      key: path,
      modified: +DateTime.local().offset,
      size: 0,
    });
    return new Response(null, { status: 204, statusText: "OK" });
  }
}
