import { DateTime } from "luxon";

import { JupyterhubService } from "../JupyterhubService";

const servers = [
  {
    owner: "user1",
    name: "server1",
    started: DateTime.local(),
    lastActivity: DateTime.local(),
    ready: true,
  },
  {
    owner: "user1",
    name: "server2",
    started: DateTime.local(),
    lastActivity: DateTime.local(),
    ready: true,
  },
  {
    owner: "user2",
    name: "server1",
    started: DateTime.local(),
    lastActivity: DateTime.local(),
    ready: true,
  },
];

export class FakeJupyterhubService implements JupyterhubService {
  async getServers() {
    return servers;
  }
  async stopServer() {
    return new Response(null, { status: 204, statusText: "OK" });
  }
}
