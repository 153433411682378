import React from "react";
import { DateTime, Duration } from "luxon";

import { ProjectId } from "../Model/Project";
import { UserPreferences } from "../Utils/LoggedUserContext";

export interface UserService {
  getCurrentUser(): Promise<{
    login: string;
    isIntegrator: boolean;
    homePageUrl: string;
    preferences: UserPreferences;
  }>;
  getUsers(): Promise<
    {
      login: string;
      isIntegrator: boolean;
      preferences: UserPreferences;
      creationDate: DateTime;
      timeSpent: Duration;
    }[]
  >;
  setCurrentProject(login: string, projectId: ProjectId): void;
  deleteUser(login: string): Promise<void>;
  grantIntegratorRights(login: string): Promise<void>;
  revokeIntegratorRights(login: string): Promise<void>;
  updatePreferences(
    sortCriteria: string,
    sortAscendant: boolean,
  ): Promise<void>;
  addUsers(
    logins: string,
    password: string,
    projects: string[],
  ): Promise<string>;
}

export const UserServiceContext = React.createContext<UserService>({
  async getCurrentUser() {
    const response = await fetch(`/services/repo2img/api/users/current`);
    const body = await response.json();
    if (response.ok) {
      return body.data;
    } else {
      console.error(body.reason);
    }
  },

  async getUsers() {
    const response = await fetch(`/services/repo2img/api/users/`);
    const body = await response.json();
    if (response.ok) {
      const rawData = body.data;
      const users = rawData.map((user: any) => {
        return {
          ...user,
          creationDate: DateTime.fromISO(user.creationDate),
          timeSpent: Duration.fromMillis(user.timeSpent),
        };
      });
      return users;
    } else {
      console.error(body.reason);
    }
  },
  setCurrentProject(login: string, projectId: ProjectId) {
    fetch(`/services/repo2img/api/users/${login}`, {
      method: "PUT",
      body: JSON.stringify({ projectId }),
    });
  },
  async deleteUser(login: string) {
    const response = await fetch(`/services/repo2img/api/users/${login}`, {
      method: "DELETE",
    });
    if (!response.ok) {
      const body = await response.json();
      console.error(body.reason);
    }
  },

  async grantIntegratorRights(login: string) {
    const response = await fetch(`/services/repo2img/api/integrators/`, {
      method: "post",
      body: JSON.stringify({ login }),
    });
    if (!response.ok) {
      const body = await response.json();
      console.error(body.reason);
    }
  },
  async revokeIntegratorRights(login: string) {
    const response = await fetch(
      `/services/repo2img/api/integrators/${login}`,
      {
        method: "delete",
      },
    );
    if (!response.ok) {
      const body = await response.json();
      console.error(body.reason);
    }
  },

  async updatePreferences(sortCriteria: string, sortAscendant: boolean) {
    const response = await fetch(`/services/repo2img/api/userpref/`, {
      method: "post",
      body: JSON.stringify({ sortCriteria, sortAscendant }),
    });
    if (!response.ok) {
      const body = await response.json();
      console.error(body.reason);
    }
  },

  async addUsers(logins: string, password: string, projects: string[]) {
    const formData = new FormData();
    formData.append("logins", logins);
    formData.append("password", password);
    formData.append("projects", projects.toString());
    const response = await fetch(`/hub/admin-user-signup`, {
      method: "post",
      body: formData,
    });
    const resp = await response.json();
    if (!response.ok) {
      console.error(resp.reason);
    }
    return resp.message;
  },
});
