import React from "react";

export type UserPreferences = {
  sortCriteria: string;
  sortAscendant: boolean;
  editableView: boolean;
};

export type LoggedUser = {
  login: string;
  isIntegrator: boolean;
  homePageUrl: string;
  preferences: UserPreferences;
};

export const LoggedUserContext = React.createContext<{
  user: LoggedUser;
  setPreferences: (prefs: UserPreferences) => void;
} | null>(null);
