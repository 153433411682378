import React from "react";

export const useModal = () => {
  const [showModal, setShowModal] = React.useState(false);
  return {
    show: showModal,
    open: () => setShowModal(true),
    close: () => setShowModal(false),
  };
};

export const useModalWithData = <T,>() => {
  const [showModal, setShowModal] = React.useState(false);
  const [data, setData] = React.useState<T>();
  return {
    show: showModal,
    open: (data: T) => {
      setData(data);
      setShowModal(true);
    },
    close: () => {
      setData(undefined);
      setShowModal(false);
    },
    data,
  };
};
