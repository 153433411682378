import React from "react";
import Button from "react-bootstrap/Button";
import FormGroup from "react-bootstrap/FormGroup";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";

import { App } from "../Model/App";
import { ProjectCreationData } from "../Services/ProjectService";
import { RequiredLabel } from "../Components/RequiredLabel";
import { ProjectServiceContext } from "../Services/ProjectService";
import { Icon } from "../Utils/Icons";
import { State } from "../reducer";

interface CreateProjectFormProps {
  show: boolean;
  onSubmit: (data: ProjectCreationData) => void;
  onCancel: () => void;
}

export const CreateProjectForm: React.FC<CreateProjectFormProps> = ({
  show,
  onSubmit,
  onCancel,
}) => {
  const projectService = React.useContext(ProjectServiceContext);
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [appId, setAppId] = React.useState("");
  const [apps, setApps] = React.useState<App[]>([]);
  const projects = useSelector((state: State) => state.projects);

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setName("");
    setDescription("");
    onSubmit({ name, description, appId });
  }

  React.useEffect(() => {
    const promises = Array.from(projects.keys(), async (projectId) => {
      return projectService.getProject(projectId).then((project) => {
        if (project) {
          return Array.from(project.apps.values());
        } else {
          return [];
        }
      });
    });
    Promise.all(promises).then((apps) => setApps(apps.flat()));
  }, [projects]);

  return (
    <Modal show={show} onHide={onCancel} backdrop="static">
      <Modal.Header closeButton={true}>
        <Modal.Title>Create a project</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <FormGroup>
            <RequiredLabel>Name</RequiredLabel>
            <input
              className="form-control"
              value={name}
              onChange={(e) => setName(e.currentTarget.value)}
              required
              autoFocus
            />
          </FormGroup>
          <FormGroup>
            <label>Description</label>
            <input
              className="form-control"
              value={description}
              onChange={(e) => setDescription(e.currentTarget.value)}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="appId">App</label>
            <select
              name="appId"
              className="form-select custom-select"
              value={appId}
              onChange={(e) => setAppId(e.currentTarget.value)}
            >
              <option key="noProjectApp" value=""></option>
              {Array.from(apps, (app) => (
                <option key={app.id.toString()} value={app.id.toString()}>
                  {app.project.name} / {app.displayName}
                </option>
              ))}
            </select>
            <Icon iconName="info-circle" />{" "}
            <em>You can add an App that will be duplicated in your project</em>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onCancel}>
            Cancel
          </Button>
          <Button className="btn-primary" type="submit">
            Create
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
