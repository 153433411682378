import { DateTime } from "luxon";

import { WebServiceId } from "./WebService";
import { ProjectDescriptor } from "./Project";

export type ServerStatus = "READY" | "SPAWNING" | "STOP" | "TERMINATED";
export type BuildStatus =
  | "PENDING"
  | "BUILDING"
  | "SUCCESS"
  | "FAILURE"
  | "ABORT";

export interface RunConfiguration {
  instanceType: string;
  serverStatus: ServerStatus;
  serverName: string;
  webServices: WebServiceId[];
  appFileName: string;
  mainWebService: WebServiceId;
  volumes: string[];
  owner: string;
}

declare const imageIdBrand: unique symbol;
export interface ImageId extends String {
  [imageIdBrand]: "IMAGE_ID_BRAND";
}
export function castImageId(id: string): ImageId {
  return id as unknown as ImageId;
}

export interface Image {
  id: ImageId;
  tag: string;
  buildStatus: BuildStatus;
  jobId: string;
  buildDuration: number;
  repoChangeset: { repoUrl: string; changeset: string; branch: string };
  availableUpdates: boolean;
  extraPackages: string;
  baseImage: string;
}

export interface AppIcon {
  startColor: string;
  stopColor: string;
  imgUrl: string;
}

declare const appIdBrand: unique symbol;
export interface AppId extends String {
  [appIdBrand]: "APP_ID_BRAND";
}
export function castAppId(id: string): AppId {
  return id as unknown as AppId;
}

export interface App {
  id: AppId;
  imageSource: Image;
  displayName: string;
  desc: string;
  icon: AppIcon;
  runConfig: RunConfiguration;
  creationDate: DateTime;
  lastUpdate: DateTime;
  project: ProjectDescriptor;
}

export type FormType =
  | {
      type: "IMAGE_CREATION";
      title: string;
    }
  | {
      type: "IMAGE_EDITION";
      title: string;
      editedImage: Image;
    }
  | {
      type: "APP_CREATION";
      projectSource: ProjectDescriptor;
    }
  | {
      type: "APP_EDITION";
      editedApp: App;
    }
  | {
      type: "APP_DUPLICATE";
      editedApp: App;
    }
  | {
      type: "APP_MOVE";
      editedApp: App;
    };
