import React from "react";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Form from "react-bootstrap/Form";

import { App, AppIcon } from "../Model/App";
import { ProjectId } from "../Model/Project";
import { ProjectServiceContext } from "../Services/ProjectService";
import { InstanceTypesForm } from "./InstanceTypesForm";
import { Icon } from "../Utils/Icons";
import { WebServicesSelector } from "./WebServicesSelector";
import { WebServiceId } from "../Model/WebService";
import { appFileTooltip } from "../Utils/Tooltips";
import { EditIconForm } from "./EditIconForm";

export const EditAppForm: React.FC<{
  show: boolean;
  onAppEdited: () => void;
  editedApp: App;
  projectId: ProjectId;
  onCancel: () => void;
}> = ({ show, onAppEdited, editedApp, projectId, onCancel }) => {
  const [instanceType, setInstanceType] = React.useState(
    editedApp.runConfig.instanceType,
  );
  const projectService = React.useContext(ProjectServiceContext);
  const [appName, setAppName] = React.useState<string>(editedApp.displayName);
  const [appDesc, setAppDesc] = React.useState<string>(editedApp.desc);
  const [appExtraVolumes, setAppExtraVolumes] = React.useState<string[]>([]);
  const [availableExtraVolumes, setAvailableExtraVolumes] = React.useState<{
    [key: string]: string;
  }>({});
  const [webService, setWebService] = React.useState<WebServiceId>(
    editedApp.runConfig.webServices[0],
  );
  const [appFileName, setAppFileName] = React.useState<string>(
    editedApp.runConfig.appFileName,
  );
  const [appIcon, setAppIcon] = React.useState<AppIcon>({
    startColor: editedApp.icon.startColor,
    stopColor: editedApp.icon.stopColor,
    imgUrl: editedApp.icon.imgUrl,
  });
  React.useEffect(() => {
    projectService.getVolumes().then((extraVolumes) => {
      setAvailableExtraVolumes(extraVolumes);
      setAppExtraVolumes(
        editedApp.runConfig.volumes.filter((value) =>
          Object.keys(extraVolumes).includes(value),
        ),
      );
    });
  }, []);

  const handleVolumesChange = (event: React.FormEvent<HTMLSelectElement>) => {
    setAppExtraVolumes(
      Array.from(event.currentTarget.options)
        .filter((o) => o.selected)
        .map((o) => o.value),
    );
  };

  function handleServiceChange(service: WebServiceId) {
    setWebService(service);
  }

  function handleAppNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    setAppName(event.currentTarget.value);
  }

  function handleAppDescChange(event: React.ChangeEvent<HTMLInputElement>) {
    setAppDesc(event.currentTarget.value);
  }

  const editApp = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = {
      name: appName,
      description: appDesc,
      icon: appIcon,
      instanceType,
      volumes: appExtraVolumes,
      webService: webService,
      appFileName: appFileName,
    };
    projectService.editApp(editedApp.id, projectId, data).then((response) => {
      if (response.ok) {
        onAppEdited();
      }
    });
  };

  return (
    <Modal show={show} onHide={onCancel} backdrop="static">
      <Modal.Header closeButton={true}>
        <Modal.Title>Edit App</Modal.Title>
      </Modal.Header>
      <Form method="post" onSubmit={editApp}>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>App Name</Form.Label>
            <Form.Control
              name="name"
              value={appName}
              onChange={handleAppNameChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>App Description</Form.Label>
            <Form.Control
              as="textarea"
              name="description"
              rows={2}
              value={appDesc}
              onChange={handleAppDescChange}
            />
            <br />
          </Form.Group>
          <details>
            <summary>Icon</summary>
            <EditIconForm
              appName={appName}
              appIcon={appIcon}
              handleIconChange={setAppIcon}
            />
          </details>
          <details>
            <summary>Advanced</summary>
            <br />
            <InstanceTypesForm
              instanceType={instanceType}
              onInstanceTypeChange={setInstanceType}
            />
            <div style={{ marginTop: "1rem" }}>
              <label>WebServices</label>
              <p>
                <em>Warning:</em> make sure your application supports the
                selected service
              </p>
              <WebServicesSelector
                availableWebServices={[
                  "lab",
                  "voila",
                  "panel",
                  "streamlit",
                  "vnc",
                  "vnclab",
                ]}
                webService={webService}
                onWebServiceClick={handleServiceChange}
              />
            </div>
            {["voila", "streamlit", "panel"].includes(webService) && (
              <Form.Group className="mb-3">
                <Form.Label>
                  <div>
                    App Filename{" "}
                    <OverlayTrigger placement="right" overlay={appFileTooltip}>
                      <span>
                        <Icon iconName="question-circle-o" />
                      </span>
                    </OverlayTrigger>
                  </div>
                </Form.Label>
                <Form.Control
                  name="name"
                  value={appFileName}
                  onChange={(e) => setAppFileName(e.currentTarget.value)}
                />
              </Form.Group>
            )}
            <br />
            {availableExtraVolumes && (
              <Form.Group className="mb-3">
                <Form.Label>Extra Volumes</Form.Label>
                <Form.Select
                  name="volumes"
                  multiple
                  value={appExtraVolumes || ""}
                  onChange={handleVolumesChange}
                >
                  {Array.from(
                    Object.entries(availableExtraVolumes),
                    ([volume, mount]) => (
                      <option key={volume} value={volume}>
                        {volume} [{mount}]
                      </option>
                    ),
                  )}
                </Form.Select>
                <Icon iconName="info-circle" />{" "}
                <em>Use [Ctrl-click] to deselect a volume</em>
              </Form.Group>
            )}
          </details>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" onClick={onCancel} className="btn btn-danger">
            Cancel
          </button>
          <input type="submit" value="Save" className="btn btn-primary" />
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
