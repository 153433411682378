import React from "react";
import { DateTime } from "luxon";
import { Map } from "immutable";

import {
  ProjectId,
  Project,
  ProjectDescriptor,
  ProjectMember,
  ProjectMemberRole,
} from "../Model/Project";
import { AppId, AppIcon, castAppId, ImageId } from "../Model/App";
import { mapFromArray } from "../Utils/utils";
import { WebServiceId } from "../Model/WebService";

export interface ProjectCreationData {
  name: string;
  description: string;
  appId?: string;
}

export interface AppCreationData {
  name: string;
  description: string;
  icon: AppIcon;
  instanceType: string;
  webService: WebServiceId;
  appFileName: string;
  volumes: string[];
  // Image creation data
  buildService: string;
  repoUrl: string;
  branch: string;
  changeset: string;
  useRepoDocker: boolean;
  notebookImageId: ImageId | null;
  extraPackages: string;
}

export interface AppEditionData {
  name: string;
  description: string;
  instanceType: string | null;
  volumes: string[];
  webService: WebServiceId;
}

export interface InstanceTypes {
  [name: string]: string;
}

export interface ProjectService {
  getProjects(): Promise<Map<ProjectId, ProjectDescriptor>>;
  getProject(projectId: ProjectId): Promise<Project | null>;
  createProject(data: ProjectCreationData): Promise<Response>;
  addProjectMember(
    projectId: ProjectId,
    login: string,
    role: ProjectMemberRole,
  ): Promise<Response>;
  deleteProjectMembers(projectId: ProjectId): Promise<Response>;
  toggleProjectMemberRole(
    projectId: ProjectId,
    login: string,
  ): Promise<Response>;
  deleteProjectMember(projectId: ProjectId, login: string): Promise<Response>;
  deleteProject(projectId: ProjectId): Promise<Response>;
  editProject(
    ProjectId: ProjectId,
    projectData: ProjectCreationData,
  ): Promise<Response>;
  createApp(appData: AppCreationData, projectId: ProjectId): Promise<Response>;
  editApp(
    appId: AppId,
    projectId: ProjectId,
    appData: AppEditionData,
  ): Promise<Response>;
  cancelBuild(appId: AppId, projectId: ProjectId): Promise<Response>;
  rebuild(
    appId: AppId,
    projectId: ProjectId,
    branch: string,
    changeset: string,
  ): Promise<Response>;
  startApp(
    appId: AppId,
    projectId: ProjectId,
    mode: "lab" | "voila" | "panel" | "vnc" | "streamlit" | "vnclab",
  ): Promise<Response>;
  stopApp(appId: AppId, projectId: ProjectId): Promise<Response>;
  duplicateApp(
    appId: AppId,
    projectId: ProjectId,
    targetProjectId: ProjectId,
  ): Promise<Response>;
  moveApp(
    appId: AppId,
    projectId: ProjectId,
    targetProjectId: ProjectId,
  ): Promise<Response>;
  deleteApp(appId: AppId, projectId: ProjectId): Promise<Response>;
  getInstanceTypes(): Promise<InstanceTypes>;
  getVolumes(): Promise<{ [key: string]: string }>;
  getLogs(appId: AppId): Promise<string>;
  checkForUpdates(appId: AppId): Promise<Response>;
}

export const ProjectServiceContext = React.createContext<ProjectService>({
  async getProjects() {
    const response = await fetch(`/services/repo2img/api/projects/`);
    if (response.ok) {
      const resp = await response.json();
      const projects = Map(
        resp["data"].map((project: any) => [
          project.id,
          {
            ...project,
            creationDate: DateTime.fromISO(project.creationDate),
          },
        ]),
      );
      return projects as unknown as Map<ProjectId, ProjectDescriptor>;
    } else {
      console.error(response);
      return Map();
    }
  },
  async getProject(projectId: ProjectId) {
    const response = await fetch(
      `/services/repo2img/api/projects/${projectId}`,
    );
    if (response.ok) {
      const json = await response.json();
      const rawData = json.data;
      const apps = Map(
        rawData.apps.map((app: any) => [
          castAppId(app.id.toString()),
          {
            ...app,
            id: castAppId(app.id.toString()),
            creationDate: DateTime.fromISO(app.creationDate),
            lastUpdate: DateTime.fromISO(app.lastUpdate),
            runConfig: {
              ...app.runConfig,
              mainWebService: app.runConfig.webServices[0],
            },
          },
        ]),
      );
      let members = mapFromArray("login", rawData.members as ProjectMember[]);
      members = members.sort((a, b) => (a.login > b.login ? 1 : -1));
      return { ...rawData, apps, members };
    } else {
      return null;
    }
  },
  createProject(projectData: ProjectCreationData) {
    return fetch(`/services/repo2img/api/projects/`, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify(projectData),
    });
  },
  addProjectMember(
    projectId: ProjectId,
    login: string,
    role: ProjectMemberRole,
  ) {
    return fetch(`/services/repo2img/api/projects/${projectId}/members/`, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify({ login, role }),
    });
  },
  deleteProjectMembers(projectId: ProjectId) {
    return fetch(`/services/repo2img/api/projects/${projectId}/members/`, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "delete",
    });
  },
  toggleProjectMemberRole(projectId: ProjectId, member: string) {
    return fetch(
      `/services/repo2img/api/projects/${projectId}/members/${member}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "put",
        body: JSON.stringify({ action: "toggleRole" }),
      },
    );
  },
  deleteProjectMember(projectId: ProjectId, member: string) {
    return fetch(
      `/services/repo2img/api/projects/${projectId}/members/${member}`,
      {
        method: "delete",
      },
    );
  },
  editProject(projectId: ProjectId, projectData: ProjectCreationData) {
    return fetch(`/services/repo2img/api/projects/${projectId}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify(projectData),
    });
  },
  deleteProject(projectId: ProjectId) {
    return fetch(`/services/repo2img/api/projects/${projectId}`, {
      method: "delete",
    });
  },
  createApp(appData: AppCreationData, projectId: ProjectId) {
    return fetch(`/services/repo2img/api/projects/${projectId}/apps`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify(appData),
    });
  },
  editApp(appId: AppId, projectId: ProjectId, appData: AppEditionData) {
    return fetch(`/services/repo2img/api/projects/${projectId}/apps/${appId}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify(appData),
    });
  },
  cancelBuild(appId: AppId, projectId: ProjectId): Promise<Response> {
    return fetch(`/services/repo2img/api/projects/${projectId}/apps/${appId}`, {
      headers: {
        Accept: "application/json",
      },
      method: "post",
      body: JSON.stringify({ action: "cancelBuild" }),
    });
  },
  rebuild(
    appId: AppId,
    projectId: ProjectId,
    branch: string,
    changeset: string,
  ) {
    return fetch(`/services/repo2img/api/projects/${projectId}/apps/${appId}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify({ action: "rebuild", branch, changeset }),
    });
  },
  async startApp(
    appId: AppId,
    projectId: ProjectId,
    mode: "lab" | "voila" | "panel" | "streamlit" | "vnclab",
  ): Promise<Response> {
    return fetch(`/services/repo2img/api/projects/${projectId}/apps/${appId}`, {
      headers: {
        Accept: "application/json",
      },
      method: "post",
      body: JSON.stringify({ action: "start", mode: mode }),
    });
  },
  async stopApp(appId: AppId, projectId: ProjectId): Promise<Response> {
    return fetch(`/services/repo2img/api/projects/${projectId}/apps/${appId}`, {
      headers: {
        Accept: "application/json",
      },
      method: "post",
      body: JSON.stringify({ action: "stop" }),
    });
  },
  duplicateApp(appId: AppId, projectId: ProjectId, targetProjectId: ProjectId) {
    return fetch(`/services/repo2img/api/projects/${projectId}/apps/${appId}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify({
        action: "duplicate",
        targetProjectId,
      }),
    });
  },
  moveApp(appId: AppId, projectId: ProjectId, targetProjectId: ProjectId) {
    return fetch(`/services/repo2img/api/projects/${projectId}/apps/${appId}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify({
        action: "move",
        targetProjectId,
      }),
    });
  },
  deleteApp(appId: AppId, projectId: ProjectId) {
    return fetch(`/services/repo2img/api/projects/${projectId}/apps/${appId}`, {
      method: "delete",
    });
  },
  async getLogs(appId: AppId) {
    const response = await fetch(`/services/repo2img/api/apps/${appId}/logs`);
    if (response.ok) {
      const resp = await response.json();
      const data = resp.data;
      return data;
    } else {
      console.error(response);
      return "Couldn't fetch logs for this build";
    }
  },
  async checkForUpdates(appId: AppId) {
    return fetch(`/services/repo2img/api/apps/${appId}/checkupdates`, {
      headers: {
        Accept: "application/json",
      },
      method: "post",
      body: JSON.stringify({ action: "checkUpdate" }),
    });
  },
  async getInstanceTypes(): Promise<InstanceTypes> {
    const response = await fetch(`/services/repo2img/api/instancetypes/`, {
      headers: {
        Accept: "application/json",
      },
    });
    if (response.ok) {
      const resp = await response.json();
      return resp.data;
    } else {
      console.error(response);
      return {};
    }
  },
  async getVolumes(): Promise<{ [key: string]: string }> {
    const response = await fetch(`/services/repo2img/api/volumes/`, {
      headers: {
        Accept: "application/json",
      },
    });
    if (response.ok) {
      const resp = await response.json();
      return resp.data;
    } else {
      return {};
    }
  },
});
