import React from "react";
import Modal from "react-bootstrap/Modal";
import { Map } from "immutable";

import { App } from "../Model/App";
import { ProjectId, ProjectDescriptor, castProjectId } from "../Model/Project";
import { ProjectServiceContext } from "../Services/ProjectService";

export const MoveAppForm: React.FC<{
  show: boolean;
  onAppEdited: () => void;
  editedApp: App;
  projectId: ProjectId;

  onCancel: () => void;
}> = ({ show, onAppEdited, editedApp, projectId, onCancel }) => {
  const projectService = React.useContext(ProjectServiceContext);
  const [projects, setProjects] =
    React.useState<Map<ProjectId, ProjectDescriptor>>(Map());
  const [targetProjectId, setTargetProjectId] = React.useState<ProjectId>(
    editedApp.project.id,
  );

  function getProjets() {
    projectService.getProjects().then(setProjects);
  }

  React.useEffect(() => {
    getProjets();
  }, []);

  const editApp = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    projectService
      .moveApp(editedApp.id, projectId, targetProjectId)
      .then((response) => {
        if (response.ok) {
          onAppEdited();
        }
      });
  };

  const handleProjectChange = (event: React.FormEvent<HTMLSelectElement>) => {
    setTargetProjectId(castProjectId(event.currentTarget.value));
  };

  return (
    <Modal show={show} onHide={onCancel} backdrop="static">
      <Modal.Header closeButton={true}>
        <Modal.Title>Move App</Modal.Title>
      </Modal.Header>
      <form method="post" role="form" onSubmit={editApp}>
        <Modal.Body>
          <div className="form-group"></div>
          <div className="form-group">
            <label htmlFor="description">
              {`Move ${editedApp.displayName} to Project: `}
            </label>
            {projects.count() == 0 ? (
              <div>
                <i
                  className="fa fa-spinner fa-pulse fa-fw"
                  aria-hidden="true"
                ></i>
                Loading Projects...
              </div>
            ) : (
              <select
                className="form-select"
                name="project"
                value={targetProjectId as unknown as string}
                onChange={handleProjectChange}
              >
                {Array.from(projects.values())
                  .filter((project) => project.id !== projectId)
                  .map((project) => (
                    <option
                      key={project.name}
                      value={project.id as unknown as string}
                    >
                      {project.name}
                    </option>
                  ))}
              </select>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" onClick={onCancel} className="btn btn-danger">
            Cancel
          </button>
          <input type="submit" value="OK" className="btn btn-primary" />
        </Modal.Footer>
      </form>
    </Modal>
  );
};
