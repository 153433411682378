import React from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

import { useLoggedUser } from "./Utils/useLoggedUser";
import { IframePage } from "./ManagementPage/IframePage";
import { UsersPage } from "./ManagementPage/UsersPage";
import { KubePage } from "./ManagementPage/KubePage";
import { ProjectsPage } from "./ProjectsPages/ProjectsPage";
import { ProjectPage } from "./ProjectsPages/ProjectPage";
import { ProfilePage } from "./ManagementPage/ProfilePage";
import { Footer } from "./Components/Footer";
import "./App.css";

export interface AppProps {
  baseUrl: string;
  featureToggles: unknown;
}

export const App: React.FC<AppProps> = ({ baseUrl }) => {
  const { user } = useLoggedUser();

  return (
    <>
      <Router basename={baseUrl}>
        <Routes>
          <Route
            path="/"
            element={
              user ? <Navigate to={user.homePageUrl} /> : <ProjectsPage />
            }
          />
          <Route path="/projects" element={<ProjectsPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/projects/:projectId" element={<ProjectPage />} />
          (user.isIntegrator &&{" "}
          {
            <>
              <Route
                path="/jhadmin"
                element={
                  <IframePage url="/hub/admin" title="Jupyterhub Admin Page" />
                }
              />
              <Route path="/users" element={<UsersPage />} />
              <Route path="/kube" element={<KubePage />} />
            </>
          }
          )
        </Routes>
      </Router>
      <Footer></Footer>
    </>
  );
};
