import React from "react";
import { createRoot } from "react-dom/client";
import { legacy_createStore as createStore } from "redux";
import { Provider as StoreProvider } from "react-redux";

import { App, AppProps } from "./App";
import { ProjectServiceContext } from "./Services/ProjectService";
import { FakeProjectService } from "./Services/Fake/FakeProjectService";
import { RepoServiceContext } from "./Services/RepoService";
import { FakeRepoService } from "./Services/Fake/FakeRepoService";
import { UserServiceContext } from "./Services/UserService";
import { fakeUserService } from "./Services/Fake/FakeUserService";
import { JupyterhubServiceContext } from "./Services/JupyterhubService";
import { FakeJupyterhubService } from "./Services/Fake/FakeJupyterhubService";
import { ProjectFileServiceContext } from "./Services/ProjectFileService";
import { UserFileServiceContext } from "./Services/UserFileService";
import { FakeProjectFileService } from "./Services/Fake/FakeProjectFileService";
import { FakeUserFileService } from "./Services/Fake/FakeUserFileService";
import { KubeServiceContext } from "./Services/KubeService";
import { FakeKubeService } from "./Services/Fake/FakeKubeService";
import { LoggedUserProvider } from "./Utils/LoggedUserProvider";
import { reducer } from "./reducer";
import "./index.css";

const defaultAppConfig = {
  baseUrl: process.env.BASE_URL,
  featureToggles: {},
};

const appProps: AppProps = window.appConfig || defaultAppConfig;
const store = createStore(reducer);

async function bootstrap() {
  // make a first request to authorize the service with oauth
  await fetch("/services/repo2img/api/users/current");
}

if (process.env.USE_FAKE_SERVICES === "true") {
  bootstrap().then(() => {
    const container = document.getElementById("root") as HTMLElement;
    const root = createRoot(container);
    root.render(
      <StoreProvider store={store}>
        <JupyterhubServiceContext.Provider value={new FakeJupyterhubService()}>
          <ProjectFileServiceContext.Provider
            value={new FakeProjectFileService()}
          >
            <KubeServiceContext.Provider value={new FakeKubeService()}>
              <UserFileServiceContext.Provider
                value={new FakeUserFileService()}
              >
                <RepoServiceContext.Provider value={new FakeRepoService()}>
                  <ProjectServiceContext.Provider
                    value={new FakeProjectService()}
                  >
                    <UserServiceContext.Provider value={fakeUserService}>
                      <LoggedUserProvider>
                        <App {...appProps} />
                      </LoggedUserProvider>
                    </UserServiceContext.Provider>
                  </ProjectServiceContext.Provider>
                </RepoServiceContext.Provider>
              </UserFileServiceContext.Provider>
            </KubeServiceContext.Provider>
          </ProjectFileServiceContext.Provider>
        </JupyterhubServiceContext.Provider>
      </StoreProvider>,
    );
  });
} else {
  bootstrap().then(() => {
    const container = document.getElementById("root") as HTMLElement;
    const root = createRoot(container);
    root.render(
      <StoreProvider store={store}>
        <LoggedUserProvider>
          <App {...appProps} />
        </LoggedUserProvider>
      </StoreProvider>,
    );
  });
}
