import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropDownButton from "react-bootstrap/DropdownButton";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Map, OrderedMap } from "immutable";

import { State } from "../reducer";
import { AppsTable } from "./AppsTable";
import { AppsCards } from "./AppsCards";
import { Page } from "../Components/Page";
import { MainMenu } from "../MainMenu";
import { Project, ProjectDescriptor, castProjectId } from "../Model/Project";
import { AppId, App, FormType } from "../Model/App";
import { ProjectServiceContext } from "../Services/ProjectService";
import { UserServiceContext } from "../Services/UserService";
import { Icon } from "../Utils/Icons";
import { useLoggedUser } from "../Utils/useLoggedUser";
import { Thumbnail } from "../Utils/Thumbnail";
import { MainTabStyle } from "../Utils/MainTab";
import { ProjectConfiguration } from "./ProjectConfiguration";
import { ProjectMembers } from "./ProjectMembers";
import { AsyncFileBrowser } from "../Utils/AsyncFileBrowser";
import { CreateAppForm } from "./CreateAppForm";
import { EditAppForm } from "./EditAppForm";
import { MoveAppForm } from "./MoveAppForm";
import { DuplicateAppForm } from "./DuplicateAppForm";

export const ProjectPage: React.FC = (): JSX.Element => {
  const params = useParams();
  const dispatch = useDispatch();
  const projectService = React.useContext(ProjectServiceContext);
  const userService = React.useContext(UserServiceContext);
  const project = useSelector((state: State) => state.currentProject);
  const setProject = (newProject: Project | null) =>
    dispatch({ type: "SET_CURRENT_PROJECT", currentProject: newProject });
  const apps = useSelector((state: State) => state.apps);
  const setApps = (newApps: Map<AppId, App>) =>
    dispatch({ type: "SET_APPS", apps: newApps });
  const { user, setPreferences } = useLoggedUser();
  const shownImageForm = useSelector((state: State) => state.formType);
  const showFormModal = useSelector((state: State) => state.showModal);
  const displayImageForm = (newForm: FormType | null) =>
    dispatch({ type: "OPEN_MODAL", form: newForm });
  const [appsFilter, setAppsFilter] = React.useState("");
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = React.useState(
    document.location.hash.substring(1),
  );
  const needAppsUpdate = apps.some(
    (app) =>
      app.imageSource.buildStatus === "PENDING" ||
      app.imageSource.buildStatus === "BUILDING" ||
      app.runConfig.serverStatus === "TERMINATED" ||
      app.runConfig.serverStatus === "SPAWNING",
  );

  function loadProject() {
    if (params.projectId) {
      projectService
        .getProject(castProjectId(params.projectId))
        .then((project) => {
          setProject(project);
          if (project) {
            userService.setCurrentProject(user.login, project.id);
            setApps(project.apps);
          }
        });
    }
  }

  React.useEffect(() => {
    loadProject();
  }, [params.projectId]);

  React.useEffect(() => {
    let intervalValue = 30000;
    if (needAppsUpdate) {
      intervalValue = 5000;
    }
    const interval = setInterval(loadProject, intervalValue);
    return function cleanup() {
      clearInterval(interval);
    };
  }, [needAppsUpdate]);

  const handleTabSelect = (tabName: string) => {
    setSelectedTab(tabName);
    navigate(`#${tabName}`);
  };

  let sortAndFilteredApps: App[] = Array.from(apps.values());
  if (appsFilter) {
    sortAndFilteredApps = sortAndFilteredApps.filter((a) =>
      a.displayName.includes(appsFilter),
    );
  }
  const order = user.preferences.sortAscendant ? "asc" : "desc";
  const criteria =
    user.preferences.sortCriteria === "name"
      ? "displayName"
      : user.preferences.sortCriteria;
  sortAndFilteredApps = sortAndFilteredApps.sort((a, b) => {
    const member = criteria as keyof typeof a;
    let result = 0;
    if (order === "asc") {
      if (a[member] > b[member]) {
        result = 1;
      } else if (a[member] < b[member]) {
        result = -1;
      }
    } else {
      if (a[member] < b[member]) {
        result = 1;
      } else if (a[member] > b[member]) {
        result = -1;
      }
    }
    return result;
  });

  const editableView = user.preferences.editableView || false;

  const swichView = () => {
    setPreferences({
      sortCriteria: user.preferences.sortCriteria,
      sortAscendant: user.preferences.sortAscendant,
      editableView: !editableView,
    });
  };

  function changeSortCriteria(criteria: keyof App | "name") {
    setPreferences({
      sortCriteria: criteria,
      sortAscendant: user.preferences.sortAscendant,
      editableView: user.preferences.editableView,
    });
  }

  function changeSortAscendant(ascendant: boolean) {
    setPreferences({
      sortCriteria: user.preferences.sortCriteria,
      sortAscendant: ascendant,
      editableView: user.preferences.editableView,
    });
  }

  const onRunnablesUpdated = () => {
    loadProject();
    dispatch({ type: "CLOSE_MODAL" });
  };

  let form;
  if (shownImageForm !== null && project && project != "MISSING_OR_FORBIDDEN") {
    switch (shownImageForm.type) {
      case "APP_EDITION":
        form = (
          <EditAppForm
            show={showFormModal}
            onAppEdited={onRunnablesUpdated}
            editedApp={shownImageForm.editedApp}
            projectId={project.id}
            onCancel={() => displayImageForm(null)}
          />
        );
        break;
      case "APP_MOVE":
        form = (
          <MoveAppForm
            show={showFormModal}
            onAppEdited={onRunnablesUpdated}
            editedApp={shownImageForm.editedApp}
            projectId={project.id}
            onCancel={() => displayImageForm(null)}
          />
        );
        break;
      case "APP_DUPLICATE":
        form = (
          <DuplicateAppForm
            show={showFormModal}
            onAppEdited={onRunnablesUpdated}
            editedApp={shownImageForm.editedApp}
            projectId={project.id}
            onCancel={() => displayImageForm(null)}
          />
        );
        break;
      case "APP_CREATION":
        form = (
          <CreateAppForm
            show={showFormModal}
            projectSource={shownImageForm.projectSource}
            onAppCreated={onRunnablesUpdated}
            onCancel={() => displayImageForm(null)}
          />
        );
        break;
      default:
        form = null;
    }
  }

  async function handleCreateAppClicked(project: ProjectDescriptor) {
    dispatch({
      type: "OPEN_MODAL",
      form: {
        type: "APP_CREATION",
        projectSource: project,
      },
    });
  }

  const hasMaintainerRights =
    project !== null &&
    project !== "MISSING_OR_FORBIDDEN" &&
    (project.owner === user.login ||
      project.members
        .filter((m) => m.role === "MAINTAINER")
        .map((m) => m.login)
        .includes(user.login));

  const tabApps = (
    <>
      <Icon iconName="cube" />
      <span className="margin-left">Apps</span>
    </>
  );
  const tabFiles = (
    <>
      <Icon iconName="file" />
      <span className="margin-left">Files</span>
    </>
  );
  const tabMembers = (
    <>
      <Icon iconName="users" />
      <span className="margin-left">Members</span>
    </>
  );
  const tabConf = (
    <>
      <Icon iconName="cog" />
      <span className="margin-left">Configuration</span>
    </>
  );
  let pageHeader = null;
  let pageContent = (
    <div className="col-md-8 managment-content">
      <i className="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"></i>
      Loading...
    </div>
  );

  if (project === "MISSING_OR_FORBIDDEN") {
    pageContent = (
      <Alert variant="warning" className="col-md-8 managment-content">
        <h4>
          <strong>Sorry:</strong> this project is unreachable !
        </h4>
      </Alert>
    );
  }

  if (
    project &&
    project !== "MISSING_OR_FORBIDDEN" &&
    project.id.toString() === params.projectId
  ) {
    pageHeader = (
      <h1 className="col-md-8 col-mdOffset-2 col-12">
        <Thumbnail $size={60}>
          <ThumbnailTextWrapper>
            {project.name.charAt(0).toUpperCase()}
          </ThumbnailTextWrapper>
        </Thumbnail>
        <span className="margin-left">{project.name}</span>
      </h1>
    );
    pageContent = (
      <div className="col-md-8 managment-content">
        <div>
          <h4>{project.description}</h4>
          <div className="managment-header" />
          <Tabs
            activeKey={selectedTab ? selectedTab : "projectApps"}
            onSelect={(e) => handleTabSelect(e as any)}
            transition={false}
            id="project-tab"
          >
            <Tab style={MainTabStyle} eventKey="projectApps" title={tabApps}>
              {!apps.isEmpty() && (
                <SortAndFilterDiv>
                  <InputGroup>
                    <FormControl
                      type="text"
                      placeholder="filter"
                      value={appsFilter}
                      onChange={(e) => setAppsFilter(e.currentTarget.value)}
                    />
                    <DropDownButton
                      title={user.preferences.sortCriteria}
                      id="sort-apps"
                      style={{
                        width: "120px",
                        borderRadius: "0px 2px 2px 0px",
                      }}
                      variant="default"
                      onSelect={(eventKey: any) => changeSortCriteria(eventKey)}
                    >
                      <Dropdown.Item eventKey="name">name</Dropdown.Item>
                      <Dropdown.Item eventKey="creationDate">
                        creationDate
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="lastUpdate">
                        lastUpdate
                      </Dropdown.Item>
                    </DropDownButton>
                    <Button
                      variant="default"
                      onClick={() =>
                        changeSortAscendant(!user.preferences.sortAscendant)
                      }
                    >
                      {user.preferences.sortAscendant ? (
                        <Icon iconName="sort-amount-asc" />
                      ) : (
                        <Icon iconName="sort-amount-desc" />
                      )}
                    </Button>
                  </InputGroup>
                </SortAndFilterDiv>
              )}
              {hasMaintainerRights && (
                <AddAppButton>
                  <EditableAppsButton
                    title="Swich view"
                    variant="default"
                    onClick={swichView}
                  >
                    {editableView ? (
                      <Icon iconName="toggle-on" />
                    ) : (
                      <Icon iconName="toggle-off" />
                    )}{" "}
                    Edit Apps
                  </EditableAppsButton>
                  <Button
                    title="Create new app"
                    variant="default"
                    onClick={() => handleCreateAppClicked(project)}
                  >
                    New App
                  </Button>
                </AddAppButton>
              )}
              {hasMaintainerRights && editableView ? (
                <AppsTable
                  apps={OrderedMap(sortAndFilteredApps.map((a) => [a.id, a]))}
                  projectId={project.id}
                  onAppUpdated={loadProject}
                />
              ) : (
                <AppsCards
                  apps={OrderedMap(sortAndFilteredApps.map((a) => [a.id, a]))}
                  projectId={project.id}
                />
              )}
            </Tab>
            <Tab style={MainTabStyle} eventKey="projectFiles" title={tabFiles}>
              <AsyncFileBrowser projectId={params.projectId} />
            </Tab>
            {hasMaintainerRights && (
              <Tab
                style={MainTabStyle}
                eventKey="projectMembers"
                title={tabMembers}
              >
                <ProjectMembers project={project} updateProject={loadProject} />
              </Tab>
            )}
            {hasMaintainerRights && (
              <Tab style={MainTabStyle} eventKey="projectConf" title={tabConf}>
                <ProjectConfiguration
                  project={project}
                  updateProject={loadProject}
                />
              </Tab>
            )}
          </Tabs>
        </div>
      </div>
    );
  }

  return (
    <Page.Content>
      <div className="container">
        <Page.Header className="row">{pageHeader}</Page.Header>
        <div className="row">
          <div className="col-md-2 context-menu">
            <MainMenu />
          </div>
          {pageContent}
          {form}
        </div>
      </div>
    </Page.Content>
  );
};

const AddAppButton = styled.section`
  float: right;
  color: #2c7bb6;
`;

const SortAndFilterDiv = styled.div`
  margin-bottom: 1rem;
  float: left;
  white-space: nowrap;
`;

const ThumbnailTextWrapper = styled.h3`
  font-size: 1.2em;
  margin-top: 10px;
  font-family: Roboto, sans-serif;
`;

const EditableAppsButton = styled(Button)`
  margin-right: 1em;
`;
