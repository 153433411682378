import React from "react";

import { Folder } from "../Model/Browser";

export interface ProjectFileService {
  getProjectFiles(projectId: string, path: string): Promise<Folder>;
  downloadFile(projectId: string, path: string): void;
  uploadFile(
    projectId: string,
    dataFile: FormData,
    folder: string,
  ): Promise<Response>;
  deleteFile(projectId: string, path: string): Promise<Response>;
  createFolder(projectId: string, path: string): Promise<Response>;
}

export const ProjectFileServiceContext =
  React.createContext<ProjectFileService>({
    async getProjectFiles(projectId: string, path: string) {
      const response = await fetch(
        `/services/repo2img/api/projects/${projectId}/files/${path}`,
        {
          headers: {
            Accept: "application/json",
          },
        },
      );
      if (response.ok) {
        const resp = await response.json();
        return resp.data;
      } else {
        const resp = await response.json();
        console.error(resp.reason);
        return null;
      }
    },
    downloadFile(projectId: string, path: string) {
      const anchor = document.createElement("a");
      anchor.href = `/services/repo2img/api/projects/${projectId}/filescontent/${path}`;
      anchor.target = "_blank";
      anchor.click();
    },
    uploadFile(projectId: string, dataFile: FormData, folder: string) {
      return fetch(
        `/services/repo2img/api/projects/${projectId}/files/${folder}`,
        {
          method: "POST",
          body: dataFile,
        },
      );
    },
    createFolder(projectId: string, path: string) {
      return fetch(
        `/services/repo2img/api/projects/${projectId}/files/${path}`,
        {
          method: "POST",
        },
      );
    },
    deleteFile(projectId: string, path: string) {
      return fetch(
        `/services/repo2img/api/projects/${projectId}/files/${path}`,
        {
          method: "DELETE",
        },
      );
    },
  });
