import React from "react";

import { Folder } from "../Model/Browser";

export interface UserFileService {
  getUserFiles(login: string, path: string): Promise<Folder>;
  downloadFile(login: string, path: string): void;
  uploadFile(
    login: string,
    dataFile: FormData,
    folder: string,
  ): Promise<Response>;
  deleteFile(login: string, path: string): Promise<Response>;
  createFolder(login: string, path: string): Promise<Response>;
}

export const UserFileServiceContext = React.createContext<UserFileService>({
  async getUserFiles(login: string, path: string) {
    const response = await fetch(
      `/services/repo2img/api/users/${login}/files/${path}`,
      {
        headers: {
          Accept: "application/json",
        },
      },
    );
    if (response.ok) {
      const resp = await response.json();
      return resp.data;
    } else {
      const resp = await response.json();
      console.error(resp.reason);
      return null;
    }
  },
  downloadFile(login: string, path: string) {
    const anchor = document.createElement("a");
    anchor.href = `/services/repo2img/api/users/${login}/filescontent/${path}`;
    anchor.target = "_blank";
    anchor.click();
  },
  uploadFile(login: string, dataFile: FormData, folder: string) {
    return fetch(`/services/repo2img/api/users/${login}/files/${folder}`, {
      method: "POST",
      body: dataFile,
    });
  },
  createFolder(login: string, path: string) {
    return fetch(`/services/repo2img/api/users/${login}/files/${path}`, {
      method: "POST",
    });
  },
  deleteFile(login: string, path: string) {
    return fetch(`/services/repo2img/api/users/${login}/files/${path}`, {
      method: "DELETE",
    });
  },
});
