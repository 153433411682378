import { Map } from "immutable";

export function assert(value: unknown): asserts value {
  if (!value) {
    throw new Error("assertion failed");
  }
}

export function entityMapFromArray<I, E extends { id: I }>(
  entityArray: E[],
): Map<I, E> {
  return Map(entityArray.map((e) => [e.id, e]));
}

export function mapFromArray<O, K extends keyof O>(key: K, objects: O[]) {
  return Map(objects.map((o) => [o[key], o]));
}
