import { AppId } from "../Model/App";
import { ProjectId } from "../Model/Project";
import { WebServiceId } from "../Model/WebService";

export function serviceUrlForServer(
  serverName: string,
  serviceId: WebServiceId,
) {
  return `/hub/${serviceId}/${serverName}`;
}

export function appShortcutUrl(
  projectId: ProjectId,
  appId: AppId,
  serviceId: WebServiceId,
) {
  return `/services/repo2img/api/projects/${projectId}/apps/${appId}/${serviceId}`;
}
