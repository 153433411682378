import Badge from "react-bootstrap/Badge";
import styled from "styled-components";

export const RoleBadge = styled(Badge)`
  background: none;
  border: 1px solid #ddd;
  color: #777;
  margin-right: 1em !important;
`;

export const OwnerRoleBadge = styled(Badge)`
  background-color: #edf3f9;
  border-radius: 10px;
  color: black;
`;
