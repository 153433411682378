import React from "react";

export const Icon: React.FC<{
  iconName: string;
  spin?: boolean;
  pulse?: boolean;
}> = ({ iconName, spin, pulse }) => {
  const classes = ["fa", `fa-${iconName}`];
  if (spin) {
    classes.push("fa-spin");
  }
  if (pulse) {
    classes.push("fa-pulse");
  }
  return <i className={classes.join(" ")} aria-hidden={true}></i>;
};

export const SpinnerIcon = () => <Icon iconName="spinner" pulse />;
export const RefreshIcon = () => <Icon iconName="refresh" />;
export const CogIcon = () => <Icon iconName="cog" />;
