import { Map } from "immutable";
import { DateTime } from "luxon";

import { assert, entityMapFromArray, mapFromArray } from "../../Utils/utils";
import { castAppId, castImageId, AppId, App } from "../../Model/App";
import {
  Project,
  ProjectId,
  ProjectMemberRole,
  castProjectId,
} from "../../Model/Project";
import {
  ProjectService,
  ProjectCreationData,
  AppEditionData,
} from "../ProjectService";

const MyImageProject = {
  id: castProjectId("1"),
  name: "My Images",
  description: "",
  owner: "me",
  creationDate: DateTime.local(),
};

function createProjectApp(options: Partial<App> = {}): App {
  const id =
    options.id ?? castAppId(Math.floor(Math.random() * 100).toString());
  return {
    id: id,
    displayName: `App ${id}`,
    desc: "",
    runConfig: {
      serverStatus: "READY",
      serverName: "",
      mainWebService: "voila",
      webServices: ["voila"],
      appFileName: "index.ipynb",
      instanceType: "default",
      volumes: [],
      owner: "me",
    },
    icon: {
      startColor: "skyBlue",
      stopColor: "springGreen",
      imgUrl: "",
    },
    imageSource: {
      id: castImageId(id.toString()),
      tag: "tag",
      buildStatus: "SUCCESS",
      jobId: "1",
      buildDuration: 15,
      repoChangeset: {
        repoUrl: "repo",
        changeset: "f4e887d5",
        branch: "branch",
      },
      availableUpdates: false,
      baseImage: "base-notebook",
      extraPackages: "",
    },
    creationDate: DateTime.local(),
    lastUpdate: DateTime.local(),
    project: MyImageProject,
    ...options,
  };
}

function createApp(options: Partial<App> = {}): App {
  const id = castAppId(Math.floor(Math.random() * 100).toString());
  return {
    id: id,
    displayName: `${id} bottles`,
    desc: "Lorem ipsum",
    runConfig: {
      instanceType: "default",
      serverStatus: "STOP",
      serverName: "",
      mainWebService: "panel",
      webServices: ["panel"],
      appFileName: "index.ipynb",
      volumes: [],
      owner: "me",
    },
    icon: {
      startColor: "blue",
      stopColor: "red",
      imgUrl: "",
    },
    imageSource: {
      id: castImageId(id.toString()),
      tag: "tag",
      buildStatus: "SUCCESS",
      jobId: "1",
      buildDuration: 15,
      repoChangeset: {
        repoUrl: "repo",
        changeset: "f4e887d5",
        branch: "branch",
      },
      availableUpdates: false,
      baseImage: "base-notebook",
      extraPackages: "",
    },
    creationDate: DateTime.local(),
    lastUpdate: DateTime.local(),
    project: MyImageProject,
    ...options,
  };
}

function appsMapFromArray(apps: App[]) {
  return Map(apps.map((t) => [t.id, t]));
}

function createProject(options: Partial<Project> = {}): Project {
  const id =
    options.id ?? castProjectId(Math.floor(Math.random() * 100).toString());
  const apps = entityMapFromArray<AppId, App>([
    createProjectApp({
      runConfig: {
        instanceType: "default",
        serverStatus: "STOP",
        serverName: "",
        mainWebService: "vnc",
        appFileName: "index.ipynb",
        webServices: ["vnc", "lab"],
        volumes: [],
        owner: "me",
      },
      displayName: "Mobile phone orchestra",
      desc: "Control an army of mobile phones and play the symphony of your life.",
    }),
    createProjectApp({
      displayName: "Nebulae chewer",
      desc: "Discover outerspace through mastication.",
    }),
  ]);
  return {
    id: id,
    name: `Project ${id}`,
    description: "",
    owner: "jdoe",
    creationDate: DateTime.local(),
    members: Map(),
    apps,
    ...options,
  };
}

export class FakeProjectService implements ProjectService {
  private nextId = 0;
  private apps: Map<AppId, App> = appsMapFromArray([createApp()]);

  private projects: Map<ProjectId, Project> = entityMapFromArray([
    createProject({
      id: castProjectId((this.nextId++).toString()),
      name: "My Images",
      description:
        "This description is somewhat short, but is still a description.",
      owner: "me",
      members: mapFromArray("login", [
        { login: "user1", role: "GUEST" },
        { login: "user2", role: "MAINTAINER" },
        { login: "user3", role: "GUEST" },
        { login: "user4", role: "GUEST" },
        { login: "user5", role: "GUEST" },
        { login: "user6", role: "MAINTAINER" },
        { login: "user7", role: "GUEST" },
        { login: "user8", role: "MAINTAINER" },
        { login: "user9", role: "GUEST" },
        { login: "user10", role: "GUEST" },
        { login: "user11", role: "GUEST" },
      ]),
    }),
    createProject({
      id: castProjectId((this.nextId++).toString()),
      name: "Python Training",
      owner: "john",
      members: mapFromArray("login", [
        { login: "user4", role: "GUEST" },
        { login: "me", role: "GUEST" },
      ]),
    }),
  ]);

  async createProject(projectData: ProjectCreationData) {
    const project: Project = {
      id: castProjectId((this.nextId++).toString()),
      owner: "me",
      creationDate: DateTime.local(),
      members: Map(),
      apps: Map(),
      ...projectData,
    };
    this.projects = this.projects.set(project.id, project);
    return new Response(null, { status: 200, statusText: "OK" });
  }

  async getProjects() {
    return this.projects;
  }

  async getProject(projectId: ProjectId) {
    return this.projects.get(projectId) || null;
  }

  async addProjectMember(
    projectId: ProjectId,
    login: string,
    role: ProjectMemberRole,
  ) {
    this.projects = this.projects.update(projectId, (project) => {
      assert(project);
      return {
        ...project,
        members: project.members.set(login, { login, role }),
      };
    });
    return new Response(null, { status: 200, statusText: "OK" });
  }

  async deleteProjectMembers(projectId: ProjectId) {
    assert(projectId);
    return new Response(null, { status: 200, statusText: "OK" });
  }

  async toggleProjectMemberRole() {
    return new Response(null, { status: 200, statusText: "OK" });
  }

  async deleteProjectMember(projectId: ProjectId, login: string) {
    this.projects = this.projects.update(projectId, (project) => {
      assert(project);
      return {
        ...project,
        members: project.members.delete(login),
      };
    });
    return new Response(null, { status: 200, statusText: "OK" });
  }

  async deleteProject(projectId: ProjectId) {
    this.projects = this.projects.delete(projectId);
    return new Response(null, { status: 200, statusText: "OK" });
  }

  async editProject(
    projectId: ProjectId,
    { name: name, description: description }: ProjectCreationData,
  ) {
    const editedProject = this.projects.get(projectId);
    if (editedProject) {
      this.projects = this.projects.set(projectId, {
        ...editedProject,
        name,
        description,
      });
    }
    return new Response(null, { status: 200, statusText: "OK" });
  }

  async createApp() {
    const id = castAppId((this.nextId++).toString());
    const app: App = {
      id: id,
      runConfig: {
        instanceType: "default",
        serverStatus: "STOP",
        serverName: "",
        mainWebService: "vnc",
        webServices: ["vnc", "lab"],
        appFileName: "index.ipynb",
        volumes: [],
        owner: "me",
      },
      icon: {
        startColor: "skyBlue",
        stopColor: "springGreen",
        imgUrl: "",
      },
      imageSource: {
        id: castImageId(id.toString()),
        tag: "tag",
        buildStatus: "BUILDING",
        jobId: "1",
        buildDuration: 10,
        repoChangeset: {
          repoUrl: "repo",
          changeset: "f4e887d5",
          branch: "branch",
        },
        availableUpdates: true,
        baseImage: "base-notebook",
        extraPackages: "",
      },
      displayName: "app1",
      desc: "description",
      creationDate: DateTime.local(),
      lastUpdate: DateTime.local(),
      project: MyImageProject,
    };
    this.apps = this.apps.set(app.id, app as unknown as App);
    return new Response(null, { status: 200, statusText: "OK" });
  }

  async startApp() {
    return new Response(JSON.stringify({ url: "jupyterhub.org" }), {
      status: 200,
      statusText: "OK",
    });
  }

  async stopApp() {
    return new Response(JSON.stringify({ url: "jupyterhub.org" }), {
      status: 200,
      statusText: "OK",
    });
  }

  async editApp(
    appId: AppId,
    projectId: ProjectId,
    { name: displayName, description: desc }: AppEditionData,
  ) {
    const editedApp = this.apps.get(appId);
    if (editedApp) {
      this.apps = this.apps.set(appId, {
        ...editedApp,
        displayName,
        desc,
      });
    }
    return new Response(null, { status: 200, statusText: "OK" });
  }

  async moveApp() {
    return new Response(null, { status: 200, statusText: "OK" });
  }

  async duplicateApp() {
    return new Response(null, { status: 200, statusText: "OK" });
  }

  async deleteApp(appId: AppId) {
    this.apps = this.apps.delete(appId);
    return new Response(null, { status: 200, statusText: "OK" });
  }

  async getInstanceTypes() {
    return { default: "" };
  }

  async getVolumes() {
    return { common: "/srv/common", training: "/srv/training" };
  }

  async rebuild() {
    return new Response(null, { status: 200, statusText: "OK" });
  }

  async cancelBuild() {
    return new Response(null, { status: 200, statusText: "OK" });
  }

  async getLogs() {
    return "Building... Please wait.";
  }

  async getRepoInformations() {
    const branches = [
      { name: "master", commit: { sha: "lkj", message: "title" } },
      { name: "main", commit: { sha: "jjjff", message: "titleee" } },
    ];
    const commit = { sha: "lkjl123lkd", message: "messgag" };
    return { branches, commit };
  }

  async checkForUpdates() {
    return new Response(null, { status: 200, statusText: "OK" });
  }
}
