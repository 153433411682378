import React from "react";

import { LoggedUserContext } from "./LoggedUserContext";

export const useLoggedUser = () => {
  const user = React.useContext(LoggedUserContext);
  if (!user) {
    throw Error("User not known");
  }
  return user;
};
