import { KubeService, NodePool } from "../KubeService";

export class FakeKubeService implements KubeService {
  async getPods() {
    return [];
  }
  async getNodes() {
    return [];
  }
  async getNodepool() {
    return { id: "fake", status: "READY", nodes: [] } as NodePool;
  }
  async updateNodepool() {
    return new Response(null, { status: 200, statusText: "OK" });
  }
}
