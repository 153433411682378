import React from "react";
import Alert from "react-bootstrap/Alert";
import { DateTime } from "luxon";

import { useDispatch } from "react-redux";
import { Map } from "immutable";
import styled from "styled-components";

import { App, AppId } from "../Model/App";
import { ProjectId } from "../Model/Project";
import { WebServiceId } from "../Model/WebService";
import { ProjectServiceContext } from "../Services/ProjectService";
import { ServerControl } from "../Utils/ServerControl";

export const AppsCards: React.FC<{
  apps: Map<AppId, App>;
  projectId: ProjectId;
}> = ({ apps, projectId }) => {
  const dispatch = useDispatch();
  const projectService = React.useContext(ProjectServiceContext);
  const [hoverInfo, setHoverInfo] = React.useState<null | AppId>(null);
  const [errorMessage, setErrorMessage] = React.useState("");

  function handleStartClicked(appId: AppId, mode: WebServiceId) {
    projectService.startApp(appId, projectId, mode).then(async (response) => {
      const data = await response.json();
      if (response.ok) {
        window.location.href = data.url;
      } else {
        console.error(data.reason);
        setErrorMessage(data.reason);
      }
    });
    dispatch({
      type: "CHANGE_APP_SERVER_STATUS",
      appId: appId,
      status: "SPAWNING",
    });
  }
  return (
    <div>
      {errorMessage && (
        <Alert variant="danger" onClose={() => setErrorMessage("")}>
          {errorMessage}
        </Alert>
      )}
      {!apps.isEmpty() ? (
        <>
          <CardsContainer>
            {Array.from(apps, ([appId, app]) => (
              <div key={appId.toString()}>
                <Card className="thumbnail" hovered={appId === hoverInfo}>
                  <CardHead>
                    <CardInfo
                      onMouseEnter={() => setHoverInfo(appId)}
                      onMouseLeave={() => setHoverInfo(null)}
                    >
                      <DetailsThumbnail>
                        <InfoIcon>i</InfoIcon>
                      </DetailsThumbnail>
                    </CardInfo>
                    <CardThumbnail
                      start_color={app.icon.startColor}
                      stop_color={app.icon.stopColor}
                    >
                      <ThumbnailTextWrapper>
                        {app.icon.imgUrl ? (
                          <img
                            width="55px"
                            height="55px"
                            src={app.icon.imgUrl}
                          />
                        ) : (
                          <>
                            {app.displayName
                              .split(" ")
                              .map((word) => word.charAt(0).toUpperCase())}
                          </>
                        )}
                      </ThumbnailTextWrapper>
                    </CardThumbnail>
                  </CardHead>
                  <CardBody>
                    <CardTitle>{app.displayName}</CardTitle>
                    <Separator />
                  </CardBody>
                  <CardFooter>
                    <ButtonWrapper>
                      <ServerControl
                        runnable={app}
                        buildStatus={app.imageSource.buildStatus}
                        onStart={(mode) => handleStartClicked(appId, mode)}
                      />
                    </ButtonWrapper>
                    <Description>
                      <DescriptionWrapper>
                        <p>
                          {app.creationDate
                            .setLocale("en-gb")
                            .toLocaleString(DateTime.DATETIME_SHORT)}
                        </p>
                        <p>{app.desc}</p>
                      </DescriptionWrapper>
                    </Description>
                  </CardFooter>
                </Card>
              </div>
            ))}
          </CardsContainer>
        </>
      ) : (
        <AlertNoApp variant="warning">
          <h4>There is no app in this project yet</h4>
        </AlertNoApp>
      )}
    </div>
  );
};

const AlertNoApp = styled(Alert)`
  margin-top: 3em;
`;

const CardsContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 1em;
  height: 600px;
  overflow-y: auto;
`;

export const CardHead = styled.div`
  position: relative;
  padding: 1rem;
`;

export const CardThumbnail = styled.div<{
  start_color: string;
  stop_color: string;
}>`
  font-weight: bold;
  color: white;
  background: linear-gradient(
    35deg,
    ${(props) => props.start_color},
    ${(props) => props.stop_color}
  );
  border-radius: 100%;
  text-align: center;
  margin-left: calc(50% - 27.5px);
`;

const InfoIcon = styled.i`
  margin-left: -3px;
`;

export const ThumbnailTextWrapper = styled.h3`
  transition:
    font-size 0.3s,
    opacity 0.3s;
  font-size: 1.5em;
  opacity: 1;
  margin-top: 8px;
  font-family: Roboto, sans-serif;
`;

const Description = styled.div`
  position: absolute;
  height: 0;
  transition: height 0.3s;
  background: white;
  overflow: hidden;
  font-size: 0.9em;
`;

const DescriptionWrapper = styled.div`
  height: 10rem;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  opacity: 1;
  transition: opacity 0.3s;
`;

const Card = styled.div<{ hovered: boolean }>`
  border-radius: 22px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px silver;
  width: 12em;
  height: 13em;
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
  margin-top: 1.5rem;

  ${CardThumbnail} {
    width: 55px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
  }
  ${({ hovered }) =>
    hovered
      ? `
    ${Description} {
      opacity: 1;
      pointer-event: none;
      height: 100%;
    }
    ${CardThumbnail} {
      width: 15px;
      height: 15px;
      margin-left: 0;
      ${ThumbnailTextWrapper} {
          font-size: 0.7em;
          opacity: 0;
      }
    }
    ${ButtonWrapper} {
        opacity: 0;
    }
    `
      : ``}}
`;

const CardInfo = styled.h3`
  margin: 0;
  position: absolute;
  right: 0.65rem;
`;

const DetailsThumbnail = styled.div`
  background: white;
  font-weight: bold;
  font-family:
    URW Chancery L,
    cursive;
  font-size: 0.8em;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  text-align: center;
  box-sizing: unset;
  border: solid #808080ab 2px;
`;

const CardTitle = styled.h4`
  height: 1.5em;
  font-size: 1.2rem;
  text-align: center;
  font-family: Roboto, sans-serif;
`;

const Separator = styled.div`
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
`;

const CardBody = styled.div`
  margin: 0rem 1rem;
`;

const CardFooter = styled.div`
  position: relative;
  margin: 1.2rem;
`;
