import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Map } from "immutable";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";

import { State } from "../reducer";
import { MainMenu } from "../MainMenu";
import { Page } from "../Components/Page";
import { ProjectDescriptor, ProjectId } from "../Model/Project";
import {
  ProjectServiceContext,
  ProjectCreationData,
} from "../Services/ProjectService";
import { Icon } from "../Utils/Icons";
import { Thumbnail } from "../Utils/Thumbnail";
import { useModal } from "../Utils/Modal";
import { useLoggedUser } from "../Utils/useLoggedUser";
import { CreateProjectForm } from "./CreateProjectForm";

import {
  List,
  ListItem,
  ListItemName,
  ListDescription,
  ListCreationDate,
} from "../Components/List";

const RightButtonToolbar = styled(ButtonToolbar)`
  float: right;
`;
const ThumbnailTextWrapper = styled.h3`
  margin-top: 10px;
  font-family: Roboto, sans-serif;
`;
const ListLink = styled(Link)`
  color: black;
  text-decoration: none;
  &:hover {
    color: black;
    background-color: #f7f9fb;
  }
  &:focus {
    color: black;
  }
`;
const ProjectsList = styled.div`
  overflow: auto;
  height: 600px;
`;

export const ProjectsPage = () => {
  const { user } = useLoggedUser();
  const dispatch = useDispatch();
  const projectService = React.useContext(ProjectServiceContext);
  const projects = useSelector((state: State) => state.projects);
  const setProjects = (newProjects: Map<ProjectId, ProjectDescriptor>) =>
    dispatch({ type: "SET_PROJECTS", projects: newProjects });
  const {
    show: showCreateProjectModal,
    open: openCreateProjectModal,
    close: closeCreateProjectModal,
  } = useModal();

  function updateProjets() {
    projectService.getProjects().then((projects) => setProjects(projects));
  }

  React.useEffect(() => {
    updateProjets();
  }, []);

  function handleCreateProjectFormSubmit(data: ProjectCreationData) {
    closeCreateProjectModal();
    projectService.createProject(data).then((response) => {
      if (response.ok) {
        updateProjets();
      }
    });
  }

  return (
    <Page.Content>
      <div className="container">
        <Page.Header className="row">
          <h1 className="col-md-8 col-mdOffset-2 col-12">
            <Thumbnail $size={60}>
              <Icon iconName="list-ul" />
            </Thumbnail>
            <span className="margin-left">Projects</span>
          </h1>
        </Page.Header>
        <div className="row">
          <div className="col-md-2 context-menu">
            <MainMenu />
          </div>
          <div className="col-md-8 managment-content">
            {user.isIntegrator && (
              <div className="managment-header">
                <RightButtonToolbar>
                  <Button
                    onClick={openCreateProjectModal}
                    variant="outline-secondary"
                  >
                    New Project
                  </Button>
                </RightButtonToolbar>
                <div className="clearfix" />
              </div>
            )}
            <CreateProjectForm
              show={showCreateProjectModal}
              onSubmit={handleCreateProjectFormSubmit}
              onCancel={closeCreateProjectModal}
            />
            <ProjectsList>
              <List>
                {Array.from(projects.values(), (project) => (
                  <ListLink
                    to={project.id.toString()}
                    key={project.id.toString()}
                  >
                    <ListItem>
                      <Thumbnail $size={40}>
                        <ThumbnailTextWrapper>
                          {project.name.charAt(0).toUpperCase()}
                        </ThumbnailTextWrapper>
                      </Thumbnail>
                      <ListItemName>{project.name}</ListItemName>
                      <ListDescription>{project.description}</ListDescription>
                      <ListCreationDate>
                        {project.creationDate.setLocale("en-gb").toISODate() ||
                          ""}
                      </ListCreationDate>
                    </ListItem>
                  </ListLink>
                ))}
              </List>
            </ProjectsList>
          </div>
        </div>
      </div>
    </Page.Content>
  );
};
