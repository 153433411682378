import styled from "styled-components";

const Content = styled.div`
  padding: 0 15px 10em;
  margin: 0 auto;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
  margin-top: 2em;

  h1 {
    margin: 3px 0 30px;
    display: flex;
    align-items: center;
    font-weight: 600;
  }

  h2 {
    margin-top: 5px;
    margin-bottom: 10px;
  }
`;

const ProjectHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;

  h1 {
    display: flex;
    align-items: center;
  }
`;

export const Page = { Content, Header, ProjectHeader };
