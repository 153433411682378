import React from "react";
import { useNavigate } from "react-router-dom";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import { Page } from "../Components/Page";
import { MainMenu } from "../MainMenu";
import { Icon } from "../Utils/Icons";
import { Thumbnail } from "../Utils/Thumbnail";
import { MainTabStyle } from "../Utils/MainTab";
import { AsyncFileBrowser } from "../Utils/AsyncFileBrowser";
import { iframeStyle } from "./IframePage";

export const ProfilePage: React.FC = () => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = React.useState(
    document.location.hash.substring(1),
  );

  const handleTabSelect = (tabName: any) => {
    setSelectedTab(tabName);
    navigate(`#${tabName}`);
  };

  const tabFiles = (
    <>
      <Icon iconName="file" />
      <span className="margin-left">Private Files</span>
    </>
  );
  const tabVCS = (
    <>
      <Icon iconName="linode" />
      <span className="margin-left">VCS Integration</span>
    </>
  );
  const tabPwd = (
    <>
      <Icon iconName="exchange" />
      <span className="margin-left">Change Password</span>
    </>
  );

  return (
    <Page.Content>
      <div className="container">
        <Page.Header className="row">
          <h1 className="col-md-8 col-mdOffset-2 col-12">
            <Thumbnail $size={60}>
              <Icon iconName="file" />
            </Thumbnail>
            <span className="margin-left">Profile</span>
          </h1>
        </Page.Header>
        <div className="row">
          <div className="col-md-2 context-menu">
            <MainMenu />
          </div>
          <div className="col-md-8 managment-content">
            <div className="managment-header" />
            <Tabs
              activeKey={selectedTab ? selectedTab : "userFiles"}
              onSelect={(e) => handleTabSelect(e)}
              transition={false}
              id="user-files"
            >
              <Tab style={MainTabStyle} eventKey="userFiles" title={tabFiles}>
                <AsyncFileBrowser />
              </Tab>
              <Tab style={MainTabStyle} eventKey="userVCS" title={tabVCS}>
                <iframe src="/hub/sources" style={iframeStyle} />{" "}
              </Tab>
              <Tab style={MainTabStyle} eventKey="userPassword" title={tabPwd}>
                <iframe src="/hub/change-password" style={iframeStyle} />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </Page.Content>
  );
};
